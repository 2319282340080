<template>
    <v-container>
        <v-dialog v-model="create_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Add Traveller
                    <v-spacer />
                    <v-btn @click="closeCreateTravellerDialog()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="new_traveller.first_name" label="First Name *" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.last_name" label="Last Name" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.email" label="Email" outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="createTraveller()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="update_traveller_dialog" persistent max-width="600">
            <v-card v-if="update_traveller_dialog">
                <v-card-title>
                    Update Traveller
                    <v-spacer />
                    <v-btn @click="closeUpdateTravellerDialog()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Basic Details
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.first_name" label="First Name *" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.last_name" label="Last Name" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.email" label="Email" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.date_of_birth" type="date"
                                            label="Date of Birth" />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                In-depth Information
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.special_requests"
                                            label="Special Requests" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.emergency_contact"
                                            label="Emergency Contact" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.accessibility_needs"
                                            label="Accessibility Needs" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.medical_info" label="Medical Info" />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Flight and Travel Details
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.nationality" label="Nationality" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.home_address" label="Home Address" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.home_country" label="Home Country" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.flight_date" type="date"
                                            label="Flight Date" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.arrival_time" type="time"
                                            label="Arrival Time" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.departure_time" type="time"
                                            label="Departure Time" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.flight_number"
                                            label="Flight Number" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="selected_traveller.airport" label="Airport" />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeUpdateTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="updateTraveller()" color="primary">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="delete_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to remove this traveller?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteTraveller()" color="red" dark>Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'CreateTravellerDialog',
    components: {
    },
    props: [
        'create_traveller_dialog', 'update_traveller_dialog', 'delete_traveller_dialog', 'itinerary', 'selected_traveller'
    ],
    data: () => ({
        new_traveller: {
            first_name: null,
            last_name: null,
            email: null,
            mobile_no: null,
            date_of_birth: null,
            special_requests: null,
            emergency_contact: null,
            accessibility_needs: null,
            medical_info: null,
            nationality: null,
            home_address: null,
            home_country: null,
            flight_date: null,
            flight_number: null,
            arrival_time: null,
            departure_time: null,
            airport: null,
        },
        panel: [0],
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        closeCreateTravellerDialog() {
            this.$emit('closeCreateTravellerDialog')
            this.new_traveller = {
                first_name: null,
                last_name: null,
                email: null,
                mobile_no: null,
                date_of_birth: null,
                special_requests: null,
                emergency_contact: null,
                accessibility_needs: null,
                medical_info: null,
                nationality: null,
                home_address: null,
                home_country: null,
                flight_date: null,
                flight_number: null,
                arrival_time: null,
                departure_time: null,
                airport: null,
            }
        },
        async createTraveller() {
            let payload = {
                ...this.new_traveller,
                itinerary_id: this.itinerary.id,
                password: this.itinerary.password,
                is_organiser: false,
            }
            if (this.itinerary.travellers.length == 0) {
                payload.is_organiser = true
            }
            await this.$axios.post('v2/itineraries/create_itinerary_traveller', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeUpdateTravellerDialog() {
            this.$emit('closeUpdateTravellerDialog')
        },
        async updateTraveller() {
            let payload = {
                ...this.selected_traveller,
            }
            await this.$axios.post(`v2/itineraries/update_itinerary_traveller/${this.selected_traveller.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeDeleteTravellerDialog() {
            this.$emit('closeDeleteTravellerDialog')
        },
        async deleteTraveller() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_traveller/${this.selected_traveller.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
    },
}
</script>