<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading_bookings"></v-skeleton-loader>
        <v-card elevation="0" v-else>
            <v-card-title class="align-start">
                <v-btn @click="refreshBookings(true, true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <div class="text-center mr-5">
                    <v-checkbox v-model="select_all" @change="selectAll()" label="Select All" class="mr-5 mt-1"
                        style="margin-bottom:-10px;" />
                    <small v-if="selected_bookings.length > 0" style="font-size:12px;">
                        {{ selected_bookings.length }} booking<label v-if="selected_bookings.length > 1">s</label>
                        selected
                    </small>
                </div>
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2 px-5" rounded
                            :disabled="selected_bookings.length == 0">
                            Send Booking Emails
                            <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="openSupplierEmailDialog('booking')">
                            <v-list-item-title>
                                Send Booking Request
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openSupplierEmailDialog('cancellation')">
                            <v-list-item-title>
                                Send Cancellation Request
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div class="text-center">
                    <v-btn @click="printVoucherPDF()" rounded color="primary" :disabled="selected_bookings.length == 0"
                        class="px-5 ml-2">
                        <v-icon class="mr-2">mdi-download</v-icon>
                        Print Vouchers
                    </v-btn>
                    <br>
                    <v-btn @click="openViewVouchersDialog()" rounded color="primary" text
                        :disabled="selected_bookings.length == 0" class="mt-2">
                        Preview
                    </v-btn>
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="publishVoucherPDF()" v-bind="attrs" v-on="on" rounded color="primary"
                            :disabled="selected_bookings.length == 0" class="px-5 ml-2">
                            <v-icon class="mr-2">mdi-content-save</v-icon>
                            Publish Vouchers
                        </v-btn>
                    </template>
                    <small>
                        Save voucher PDF to client documents.
                        <br>
                        Client will be able to view on the portal.
                    </small>
                </v-tooltip>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-data-table :headers="booking_table_header" :items="bookings" hide-default-footer disable-pagination
                    item-key="id" :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr :set="days = (itinerary.count_transpo_end_date == 1 && item.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                            ? $date(item.check_out_date).diff($date(item.check_in_date), 'd') + 1 : $date(item.check_out_date).diff($date(item.check_in_date), 'd')"
                            :class="selected_bookings.includes(item) ? 'selected' : ''">
                            <td>
                                <input @click="selectBooking(item)"
                                    :checked="selected_bookings.findIndex((booking) => booking.id == item.id) > -1 ? true : false"
                                    type="checkbox" style="cursor:pointer;width:20px;height:20px;" />
                            </td>
                            <td>
                                <div class="d-flex">
                                    <b v-if="item.option.product.supplier.service_type_id == 1">
                                        {{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                                    </b>
                                    <b v-else>
                                        {{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                                    </b>
                                    <v-tooltip
                                        v-if="itinerary.count_transpo_end_date == 1 && item.option.product.supplier.service_type_id == 3 && days > 1"
                                        top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <label v-bind="attrs" v-on="on" style="color:red;">
                                                *
                                            </label>
                                        </template>
                                        <small>
                                            End date is counted for this itinerary
                                        </small>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            <b>{{ item.option.product.supplier.supplier_name }}</b>
                                            <small v-if="item.option.product.supplier.is_quick_supplier == 1"
                                                class="ml-2">(quick
                                                supplier)</small>
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="item.option.product">
                                            <small class="ml-3 text-wrap">{{ item.option.product.name }}</small>
                                            <br>
                                            <small class="ml-3 text-wrap">
                                                <b>x{{ item.count }}</b>
                                                {{ item.option.name }}
                                            </small>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </td>
                            <td>
                                {{ item.option.product.supplier.county }}
                            </td>
                            <td>
                                <div>
                                    <v-icon color="black">
                                        {{
                                            item.option.product.supplier.service_type_id == 1
                                                ? 'mdi-bed' : item.option.product.supplier.service_type_id == 2
                                                    ? 'mdi-walk' : item.option.product.supplier.service_type_id == 3
                                                        ? 'mdi-car-back' : 'mdi-plus-circle'
                                        }}
                                    </v-icon>
                                </div>
                            </td>
                            <td>
                                <small>
                                    {{ $date(item.check_in_date).format('MMM DD, YYYY') }}
                                    <br>
                                    <label v-if="item.check_out_date != item.check_in_date">
                                        {{ $date(item.check_out_date).format('MMM DD, YYYY') }}
                                    </label>
                                </small>
                            </td>
                            <td>
                                <small>
                                    <label v-if="item.check_in_time">
                                        {{ $date(item.check_in_date + ' ' + item.check_in_time).format('h:mm A') }}
                                    </label>
                                    <br>
                                    <label v-if="item.check_out_time && item.check_out_time != item.check_in_time">
                                        {{ $date(item.check_out_date + ' ' + item.check_out_time).format('h:mm A') }}
                                    </label>
                                </small>
                            </td>
                            <td>
                                <v-select :items="get_selected_company.id == 5 ? [...get_booking_statuses] : [...get_booking_statuses.filter(x => x.id < 9)]" v-model="item.status_id"
                                    @change="updateBooking(item)" item-text="status" item-value="id" chips>
                                    <template #selection="{ item }">
                                        <v-chip :color="getColor(item.id)" style="cursor:pointer;">
                                            {{ item.status }}
                                        </v-chip>
                                    </template>
                                </v-select>
                            </td>
                            <td>
                                <v-btn @click="openUpdateBookingDialog(item)" text>
                                    {{ item.booking_reference_number }}
                                    <v-icon class="ml-5">mdi-pen</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="update_supplier_reference_dialog" persistent max-width="600">
            <v-card v-if="selected_booking">
                <v-card-title>
                    <label>Update supplier reference ID</label>
                </v-card-title>
                <v-divider />
                <v-card-text class="mt-5">
                    <v-text-field v-model="selected_booking.booking_reference_number" label="Supplier Reference ID"
                        dense outlined>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined class="pl-3 pr-3" rounded @click="closeUpdateBookingDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                    <v-btn color="primary" class="pl-8 pr-8" rounded @click="updateBooking(selected_booking)">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ViewVouchersDialog :view_vouchers_dialog="view_vouchers_dialog" :itinerary="itinerary"
            :selected_bookings="selected_bookings" @close="closeViewVouchersDialog" />
        <SupplierDetailsDialog v-if="selected_booking" :supplier_details_dialog="supplier_details_dialog"
            :selected_supplier="selected_booking.option.product.supplier" @close="closeSupplierDetailsDialog" />
        <SupplierEmailDialog v-if="supplier_email_dialog" :supplier_email_type="supplier_email_type"
            :supplier_email_dialog="supplier_email_dialog" :grouped_bookings="grouped_bookings"
            @close="closeSupplierEmailDialog" @sendSupplierEmails="sendSupplierEmails" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'
import SupplierDetailsDialog from '../dialogs/SupplierDetailsDialog.vue'
import SupplierEmailDialog from './dialogs/SupplierEmailDialog.vue'
import ViewVouchersDialog from './dialogs/ViewVouchersDialog.vue'
export default {
    name: 'BookingComponent',
    components: {
        SupplierDetailsDialog, SupplierEmailDialog, ViewVouchersDialog,
    },
    props: [
        'itinerary', 'loading_bookings', 'bookings'
    ],
    data() {
        return {
            app_url: null,
            booking_table_header: [
                { text: '', width: '1px' },
                { text: 'Days/Nts', value: 'days', sortable: true, width: '50px' },
                { text: 'Item', value: 'option.product.supplier.supplier_name', sortable: true, width: '350px' },
                { text: 'County', value: 'option.product.supplier.county', sortable: true, width: '50px' },
                { text: 'Type', value: 'option.product.supplier.service_type_id', sortable: true, width: '50px' },
                { text: 'Dates', value: 'check_in_date', sortable: true, width: '100px' },
                { text: 'Time', value: 'check_in_time', sortable: true, width: '100px' },
                { text: 'Status', value: 'status_id', sortable: true, width: '350px' },
                { text: 'Supplier Reference', value: 'booking_reference_number' }
            ],
            sort_by: 'check_in_date',
            sort_desc: false,
            selected_booking: null,
            selected_bookings: [],
            grouped_bookings: [],
            supplier_details_dialog: false,
            update_supplier_reference_dialog: false,
            supplier_email_dialog: false,
            cancellation_request_dialog: false,
            selected_supplier: null,
            supplier_email_type: null,
            select_all: false,
            view_vouchers_dialog: false,
        }
    },
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_booking_statuses: 'itineraryV2/get_booking_statuses',
        }),
    },
    methods: {
        async refreshBookings(message = false, success = false) {
            this.$emit('refreshBookings', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        selectBooking(booking) {
            let index = this.selected_bookings.findIndex((item) => item.id == booking.id)
            if (index == -1) {
                this.selected_bookings.push(booking)
            }
            else {
                this.selected_bookings.splice(index, 1)
            }
            if (this.selected_bookings.length == this.bookings.length) {
                this.select_all = true
            }
            else {
                this.select_all = false
            }
        },
        selectAll() {
            if (this.selected_bookings.length == this.bookings.length) {
                this.selected_bookings = []
            }
            else {
                this.selected_bookings = []
                this.bookings.forEach(booking => {
                    this.selected_bookings.push(booking)
                })
            }
        },
        openUpdateBookingDialog(data) {
            this.update_supplier_reference_dialog = true
            this.selected_booking = data
        },
        closeUpdateBookingDialog() {
            this.update_supplier_reference_dialog = false
            this.selected_booking = null
        },
        async updateBooking(item) {
            let payload = {
                ...item,
            }
            await this.$axios.post(`v2/itineraries/update_booking/${this.itinerary.id}/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateBookingDialog()
                    }
                })
        },
        openSupplierEmailDialog(type) {
            this.supplier_email_type = type
            const supplierMap = {}
            let indexCounter = 0
            this.grouped_bookings = []
            this.selected_bookings.forEach((booking) => {
                const supplierId = booking.option.product.supplier.id
                if (supplierMap[supplierId] === undefined) {
                    supplierMap[supplierId] = indexCounter
                    this.grouped_bookings[indexCounter] = []
                    indexCounter++
                }
                const groupIndex = supplierMap[supplierId]

                if (this.supplier_email_type == 'booking') {
                    booking.subject = this.itinerary.company.company_name + ': Booking Request For ' + this.itinerary.itinerary_name
                }
                else if (this.supplier_email_type == 'cancellation') {
                    booking.subject = this.itinerary.company.company_name + ': Cancellation Request For ' + this.itinerary.itinerary_name
                }
                this.grouped_bookings[groupIndex].push(booking)
            })

            this.grouped_bookings.forEach(supplier_bookings => {
                let booking_ids = ''
                supplier_bookings[0].email = 'Dear Reservations,<br><br>Greetings! '

                if (this.supplier_email_type == 'booking') {
                    supplier_bookings[0].email += 'Please reserve and confirm the following bookings:<br><br>'
                }
                else if (this.supplier_email_type == 'cancellation') {
                    supplier_bookings[0].email += 'Please cancel the following bookings:<br><br>'
                }

                supplier_bookings[0].email += '<b>Supplier Name: </b>' + supplier_bookings[0].option.product.supplier.supplier_name + '<br>'
                supplier_bookings[0].email += '<b>Supplier Email: </b>' + supplier_bookings[0].option.product.supplier.email + '<br>'
                supplier_bookings[0].email += '<b>Itinerary Name: </b>' + this.itinerary.itinerary_name + '<br>'
                supplier_bookings[0].email += '<b>Itinerary Reference ID: </b>' + this.itinerary.itinerary_number + '<br>'
                supplier_bookings.forEach((booking, index) => {
                    booking_ids += booking.id + ','
                    let days = (this.itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')

                    supplier_bookings[0].email += '<br><br><h3>Item ' + (index + 1) + '</h3>'
                    supplier_bookings[0].email += '<b>Booking Reference ID: </b><label>' + booking.id + '</label><br>'
                    supplier_bookings[0].email += '<b>Product Name: </b><label>' + booking.option.product.name + '</label><br>'
                    supplier_bookings[0].email += '<b>Option Name: </b><label>' + booking.option.name + '</label><br>'
                    if (booking.option.product.supplier.service_type_id == 1) {
                        supplier_bookings[0].email += '<b>Check In Date: </b><label>' + (booking.check_in_date ? this.$date(booking.check_in_date).format('MMMM D, YYYY') : 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>Check Out Date: </b><label>' + (booking.check_out_date ? this.$date(booking.check_out_date).format('MMMM D, YYYY') : 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>Check In Time: </b><label>' + (booking.check_in_time ?? 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>Check Out Time: </b><label>' + (booking.check_out_time ?? 'N/A') + '</label><br>'
                    }
                    else {
                        supplier_bookings[0].email += '<b>Start Date: </b><label>' + (booking.check_in_date ? this.$date(booking.check_in_date).format('MMMM D, YYYY') : 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>End Date: </b><label>' + (booking.check_out_date ? this.$date(booking.check_out_date).format('MMMM D, YYYY') : 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>Start Time: </b><label>' + (booking.check_in_time ?? 'N/A') + '</label><br>'
                        supplier_bookings[0].email += '<b>End Time: </b><label>' + (booking.check_out_time ?? 'N/A') + '</label><br>'
                    }
                    supplier_bookings[0].email += '<b>Item Type: </b><label>' + booking.option.product.type.name + '</label><br>'
                    if (booking.option.product.supplier.service_type_id == 1) {
                        supplier_bookings[0].email += '<b>Number of Rooms: </b><label>' + booking.count + '</label><br>'
                        supplier_bookings[0].email += '<b>Number of Nights: </b><label>' + (days > 0 ? days : 1) + '</label><br>'
                    }
                    else if (booking.option.product.supplier.service_type_id == 3) {
                        supplier_bookings[0].email += '<b>Number of Pax: </b><label>' + this.itinerary.travellers_number + '</label><br>'
                        supplier_bookings[0].email += '<b>Number of Days: </b><label>' + (days > 0 ? days : 1) + '</label><br>'
                        supplier_bookings[0].email += '<b>Pick Up Location: </b><label>' + booking.option.product.supplier.address + '</label><br>'
                        supplier_bookings[0].email += '<b>Drop Off Location: </b><label>' + booking.option.product.supplier.address + '</label><br>'
                    }
                    else {
                        supplier_bookings[0].email += '<b>Quantity/Count: </b><label>' + booking.count + '</label><br>'
                        supplier_bookings[0].email += '<b>Number of Days: </b><label>' + (days > 0 ? days : 1) + '</label><br>'
                    }
                    if (booking.rate_day != null) {
                        supplier_bookings[0].email += '<b>Rate Per Day Per Count: </b><label>' + (booking.option.product.supplier.currency ? booking.option.product.supplier.currency.symbol : '') + parseFloat(booking.rate_day.net).toFixed(2) + '</label><br>'
                        supplier_bookings[0].email += '<b>Total Price: </b><label>' + (booking.option.product.supplier.currency ? booking.option.product.supplier.currency.symbol : '') + parseFloat(booking.rate_day.net * (days > 0 ? days : 1) * booking.count).toFixed(2) + '</label><br>'
                    }
                    else {
                        supplier_bookings[0].email += '<b>Rate Per Day Per Count: </b><label>' + 'No rate found for this period' + '</label><br>'
                        supplier_bookings[0].email += '<b>Total Price: </b><label>' + 'No rate found for this period' + '</label><br>'
                    }
                })

                supplier_bookings[0].email += '<br><br><b>Booking Notes:</b><br><br>'

                if (this.supplier_email_type == 'booking') {
                    supplier_bookings[0].email += '<br><label>If you have availability and all details including price are correct, please click confirm:</label>'
                }
                else if (this.supplier_email_type == 'cancellation') {
                    supplier_bookings[0].email += '<br><label>To confirm this cancellation request in our system, please click confirm:</label>'
                }

                supplier_bookings[0].email += '<div style="text-align:center;">'

                if (this.supplier_email_type == 'booking') {
                    let confirm_link = this.app_url + 'confirm/' + this.itinerary.id + '/' + booking_ids
                    let confirm_link_style = 'background-color:#1976D2;color:white!important;border-radius:8px;padding: 20px 40px;font-weight:600;font-size:16px;'
                    supplier_bookings[0].email += '<br><br><br><a href="' + confirm_link + '" style="' + confirm_link_style + '">CONFIRM</a><br><br><br>'

                    let decline_link = this.app_url + 'decline/' + this.itinerary.id + '/' + booking_ids
                    let decline_link_style = 'background-color:red;color:white!important;border-radius:8px;padding: 20px 40px;font-weight:600;font-size:16px;'
                    supplier_bookings[0].email += '<br><a href="' + decline_link + '" style="' + decline_link_style + '">DECLINE</a><br><br><br>'
                }
                else if (this.supplier_email_type == 'cancellation') {
                    let cancel_link = this.app_url + 'cancel/' + this.itinerary.id + '/' + booking_ids
                    let cancel_link_style = 'background-color:#1976D2;color:white!important;border-radius:8px;padding: 20px 40px;font-weight:600;font-size:16px;'
                    supplier_bookings[0].email += '<br><br><br><a href="' + cancel_link + '" style="' + cancel_link_style + '">CONFIRM</a><br><br><br>'
                }
                supplier_bookings[0].email += '</div>'

                if (this.supplier_email_type == 'booking') {
                    supplier_bookings[0].email += '<label>If you have availability at different rates or any alternative options, please reply to this email.</label><br><br>'
                }
                supplier_bookings[0].email += '<label>We look forward to receiving your response.</label><br><br><label>Regards!</label>'
            })
            this.supplier_email_dialog = true
        },
        closeSupplierEmailDialog() {
            this.supplier_email_dialog = false
        },
        async sendSupplierEmails(bookings_to_send) {
            this.$toast.info('Sending, please wait...')
            let payload = {
                company: this.itinerary.company,
                itinerary_name: this.itinerary.itinerary_name,
                grouped_bookings: bookings_to_send,
                supplier_email_type: this.supplier_email_type,
            }
            await this.$axios.post('v2/itineraries/send_supplier_emails', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeSupplierEmailDialog()
                        this.selected_bookings = []
                        this.select_all = false
                        this.refreshBookings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCancellationRequestDialog() {
            this.cancellation_request_dialog = true
        },
        closeCancellationRequestDialog() {
            this.cancellation_request_dialog = false
        },
        async sendCancellationRequest() {

        },
        openSupplierDetailsDialog(item) {
            this.supplier_details_dialog = true
            this.selected_booking = item
        },
        closeSupplierDetailsDialog() {
            this.supplier_details_dialog = false
            this.selected_booking = null
        },
        async printVoucherPDF() {
            this.openViewVouchersDialog()
            this.$toast('Printing...')
            setTimeout(() => {
                if (this.selected_bookings.length > 0) {
                    var options = {
                        margin: 0.2,
                        filename: this.itinerary.itinerary_name + "-voucher.pdf",
                        image: { type: 'jpeg' },
                        html2canvas: { scale: 1.2, useCORS: true },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
                    }
                    html2pdf().set(options).from(document.getElementById('voucher-list')).save()
                }
                this.closeViewVouchersDialog()
                this.$toast.success('Printed successfully')
            }, 500)
        },
        async publishVoucherPDF() {
            this.openViewVouchersDialog()
            this.$toast('Saving...')
            setTimeout(() => {
                this.closeViewVouchersDialog()
                if (this.selected_bookings.length > 0) {
                    this.$toast('Saving, please wait...')
                    const element = document.getElementById('voucher-list')
                    html2pdf().set({
                        margin: 0.2,
                        filename: this.itinerary.itinerary_name + '-voucher.pdf',
                        image: { type: 'jpeg' },
                        html2canvas: { scale: 1, useCORS: true },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
                    }).from(element).toPdf().get('pdf').then((pdf) => {
                        const blob = pdf.output('blob')

                        const formData = new FormData()
                        formData.append('itinerary_id', this.itinerary.id)
                        formData.append('type', 'client voucher')
                        formData.append('file', blob, this.itinerary.itinerary_name + '-voucher.pdf')

                        this.$axios.post('v2/itineraries/create_itinerary_document', formData)
                            .then(({ data }) => {
                                if (data.response) {
                                    this.$toast.success(data.message)
                                    this.$emit('refreshItinerary')
                                }
                                else {
                                    this.$toast.error(data.message)
                                }
                            })
                    })
                }
            }, 500)
        },
        openViewVouchersDialog() {
            this.view_vouchers_dialog = true
        },
        closeViewVouchersDialog() {
            this.view_vouchers_dialog = false
        },
        getColor(status_id) {
            if (status_id != null) {
                switch (status_id) {
                    case 1:
                        return 'primary'
                    case 2:
                        return 'warning'
                    case 3:
                        return 'success'
                    case 4:
                        return 'error'
                    case 5:
                        return 'error'
                    case 6:
                        return '#E6E6FA'
                    case 7:
                        return 'error'
                    default:
                        return 'primary'
                }
            } else {
                return 'primary'
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: right;
}

.card .card-header-items .header-items {
    margin: 0px 10px;
}

.dialog-close {
    margin-left: -75px;
    position: absolute;
}

.custom-label {
    background-color: grey;
    border-radius: 10px;
    color: white;
    font-size: 9px;
    padding: 3px 5px;
}

:deep(div) {
    page-break-inside: avoid;
}

:deep(.v-input__slot::before) {
    border-style: none !important;
}

.selected,
.selected:hover {
    background-color: #BBE0F9 !important;
}
</style>