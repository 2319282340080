<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading_bookings"></v-skeleton-loader>
        <v-row v-else justify="center">
            <v-col cols="12">
                <v-card elevation="0">
                    <v-card-title class="align-start">
                        <v-btn @click="refreshBookings(true, true)" rounded color="primary" class="mx-2">
                            <v-icon class="mr-2">mdi-refresh</v-icon>
                            Refresh
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on" rounded
                                    :disabled="selected_bookings.length == 0">
                                    Send {{ selected_bookings.length }} payment notification<label
                                        v-if="selected_bookings.length > 1">s</label>
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="openSupplierEmailDialog('payment')">
                                    <v-list-item-title>
                                        Send Payment Notification
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <download-csv :data="bookingsCsv">
                            <v-btn rounded color="primary" class="ml-2">
                                <v-icon class="mr-2">mdi-download</v-icon>
                                Export CSV
                            </v-btn>
                        </download-csv>
                        <!-- <v-btn @click="printPDF()" rounded color="primary" class="ml-2">
                            <v-icon class="mr-2">mdi-download</v-icon>
                            Print PDF
                        </v-btn> -->
                        <div class="text-center">
                            <v-btn @click="saveDocument()" rounded color="primary" class="ml-2">
                                <v-icon class="mr-2">mdi-content-save</v-icon>
                                Save to History
                            </v-btn>
                            <br>
                            <v-btn @click="openViewDocumentsDialog()" rounded color="primary" class="ml-2 mt-3" text>
                                View Pricing History
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" style="display:flex;margin-top:20px;">
                                <v-text-field type="number" v-model="itinerary.service_charge" label="Service Charge"
                                    outlined dense style="max-width:300px;margin-right:20px;"></v-text-field>
                                <v-text-field type="number" v-model="itinerary.orr" label="ORR" outlined dense
                                    style="max-width:300px;margin-right:20px;"></v-text-field>
                                <v-text-field type="number" v-model="margin_percent" label="Margin %" outlined dense
                                    style="max-width:300px;"></v-text-field>
                                <v-btn @click="saveCharges()" dark class="ml-2">Save</v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-switch v-model="show_occupancy" label="Show occupancy options"></v-switch>
                            </v-col>
                            <v-col cols="4" class="d-flex">
                                <div class="mx-5">
                                    <strong>Net Total:<br>
                                        {{ itinerary.currency.code }}
                                        {{ net | formatNumber }}
                                    </strong>
                                </div>
                                <div class="mx-5">
                                    <strong>Gross Total:<br>
                                        {{ itinerary.currency.code }}
                                        {{ gross | formatNumber }}
                                    </strong>
                                </div>
                                <div class="mx-5">
                                    <strong>
                                        Margin:<br>
                                        {{ itinerary.currency.code }} {{ gross - net | formatNumber }}
                                        ({{ ((gross - net) / gross * 100).toFixed(2) }}%)
                                    </strong>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" style="text-align:right;">
                                <v-btn @click="bookings_to_hide = []" text v-if="bookings_to_hide.length > 0">Show all
                                    <v-icon>mdi-eye-outline</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <section id="pricing-table">
                            <v-app>
                                <v-data-table
                                    :headers="show_occupancy ? pricing_table_header_occupancy : pricing_table_header"
                                    :items="bookings" class="mt-5" hide-default-footer disable-pagination
                                    :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                                    <template v-slot:item="{ item }">
                                        <tr v-if="bookings_to_hide.indexOf(item.id) == -1"
                                            :set="days = (itinerary.count_transpo_end_date == 1 && item.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                                                ? $date(item.check_out_date).diff($date(item.check_in_date), 'd') + 1 : $date(item.check_out_date).diff($date(item.check_in_date), 'd')"
                                            :class="selected_bookings.includes(item) ? 'selected' : !item.option.is_free && (item.rate_day == null || item.rate_day.net == 0) ? 'no-rates' : ''">
                                            <td>
                                                <input @click="selectBooking(item)"
                                                    :checked="selected_bookings.findIndex((booking) => booking.id == item.id) > -1 ? true : false"
                                                    type="checkbox" style="cursor:pointer;width:20px;height:20px;" />
                                            </td>
                                            <td>
                                                <b>{{ item.id }}</b>
                                            </td>
                                            <td>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="text-wrap">
                                                            <b>{{ item.option.product.supplier.supplier_name }}</b>
                                                            <small
                                                                v-if="item.option.product.supplier.is_quick_supplier == 1"
                                                                class="ml-2">(quick supplier)</small>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-if="item.option.product">
                                                            <small class="ml-3 text-wrap">{{ item.option.product.name
                                                                }}</small>
                                                            <br>
                                                            <small class="ml-3 text-wrap">
                                                                <b>x{{ item.count }}</b>
                                                                {{ item.option.name }}
                                                                <b v-if="item.option.is_free" class="ml-5">(Free)</b>
                                                            </small>
                                                            <br>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </td>
                                            <td>
                                                <div>
                                                    <v-icon color="black" size="20">
                                                        {{
                                                            item.option.product.supplier.service_type_id == 1
                                                                ? 'mdi-bed' : item.option.product.supplier.service_type_id == 2
                                                                    ? 'mdi-walk' : item.option.product.supplier.service_type_id == 3
                                                                        ? 'mdi-car-back' : 'mdi-plus-circle'
                                                        }}
                                                    </v-icon>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <b v-if="item.option.product.supplier.service_type_id == 1">
                                                        {{ (days > 0 ? days : 1) }} night<label
                                                            v-if="days > 1">s</label>
                                                    </b>
                                                    <b v-else>
                                                        {{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                                                    </b>
                                                    <v-tooltip
                                                        v-if="itinerary.count_transpo_end_date == 1 && item.option.product.supplier.service_type_id == 3 && days > 1"
                                                        top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <label v-bind="attrs" v-on="on" style="color:red;">
                                                                *
                                                            </label>
                                                        </template>
                                                        <small>
                                                            End date is counted for this itinerary
                                                        </small>
                                                    </v-tooltip>
                                                </div>
                                                <small>
                                                    {{ $date(item.check_in_date).format('MMM DD, YYYY') }}
                                                    <br>
                                                    <label v-if="item.check_out_date != item.check_in_date">
                                                        {{ $date(item.check_out_date).format('MMM DD, YYYY') }}
                                                    </label>
                                                </small>
                                            </td>
                                            <!-- NET RATE-->
                                            <td>
                                                <v-list-item v-if="item.rate_day">
                                                    <v-list-item-content
                                                        :set="rate = item.rate_day.net * (days > 0 ? days : 1) * item.count"
                                                        :set2="converted_rate = item.rate_day.net * conversion * (days > 0 ? days : 1) * item.count">
                                                        <v-list-item-title>
                                                            <small>
                                                                <b>{{ itinerary.currency.code }} </b>
                                                                <label
                                                                    v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                    <b>{{ rate.toFixed(2) }}</b>
                                                                </label>
                                                                <label v-else>
                                                                    <b>{{ converted_rate.toFixed(2) }}</b>
                                                                </label>
                                                            </small>
                                                            <v-tooltip
                                                                v-if="item.status_id == 3 && $date(item.confirmed_at).isBefore($date('2024-12-21'), 'day')"
                                                                top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <label v-bind="attrs" v-on="on">
                                                                        –
                                                                    </label>
                                                                </template>
                                                                <small>
                                                                    <h2>Using initial night rates</h2>
                                                                    Booking already confirmed before 12-21-2024
                                                                </small>
                                                            </v-tooltip>
                                                            <v-tooltip v-if="item.rate_days.length > 0" top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <label v-bind="attrs" v-on="on"
                                                                        :style="item.rate_days.filter(x =>
                                                                            x.day == $date(item.check_in_date).format('dddd'))[0].net == item.rate_day.net
                                                                            ? 'color:grey;' : item.rate_days.filter(x =>
                                                                                x.day == $date(item.check_in_date).format('dddd'))[0].net > item.rate_day.net ? 'color:blue;' : 'color:red;'">
                                                                        *
                                                                    </label>
                                                                </template>
                                                                <small>
                                                                    <h2>Using per night rates</h2>
                                                                    Initial night rates:
                                                                    <br>
                                                                    <b style="font-weight:800;">
                                                                        {{ itinerary.currency.code }}
                                                                        <label
                                                                            v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                            {{
                                                                                parseFloat(item.rate_days.filter(x =>
                                                                                    x.day ==
                                                                                    $date(item.check_in_date).format('dddd'))
                                                                                [0].net * (days > 0 ? days : 1) *
                                                                                    item.count).toFixed(2)
                                                                            }}
                                                                        </label>
                                                                        <label v-else>
                                                                            {{
                                                                                parseFloat(item.rate_days.filter(x =>
                                                                                    x.day ==
                                                                                    $date(item.check_in_date).format('dddd'))
                                                                                [0].net * conversion * (days > 0 ? days :
                                                                                    1) *
                                                                                    item.count).toFixed(2)
                                                                            }}
                                                                        </label>
                                                                    </b>
                                                                    <br>
                                                                    {{ itinerary.currency.code }}
                                                                    <label
                                                                        v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                        {{
                                                                            parseFloat(item.rate_days.filter(x =>
                                                                                x.day ==
                                                                                $date(item.check_in_date).format('dddd'))
                                                                            [0].net).toFixed(2)
                                                                        }}
                                                                    </label>
                                                                    <label v-else>
                                                                        {{
                                                                            parseFloat(item.rate_days.filter(x =>
                                                                                x.day ==
                                                                                $date(item.check_in_date).format('dddd'))
                                                                            [0].net * conversion).toFixed(2)
                                                                        }}
                                                                    </label>
                                                                    per count per night
                                                                </small>
                                                            </v-tooltip>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <small>
                                                                {{ itinerary.currency.code }}
                                                                <label
                                                                    v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                    {{ item.rate_day.net }}
                                                                </label>
                                                                <label v-else>
                                                                    {{ (item.rate_day.net * conversion).toFixed(2) }}
                                                                </label>
                                                                <br>*
                                                                <label
                                                                    v-if="item.option.product.supplier.service_type_id == 1">
                                                                    {{ (days > 0 ? days : 1) }} night<label
                                                                        v-if="days > 1">s</label>
                                                                </label>
                                                                <label v-else>
                                                                    {{ (days > 0 ? days : 1) }} day<label
                                                                        v-if="days > 1">s</label>
                                                                </label>
                                                                <br>* {{ item.count }} count
                                                                <br>
                                                                <label style="color:grey;"
                                                                    v-if="item.option.product.supplier.currency_id != itinerary.currency_id">
                                                                    ({{ item.option.product.supplier.currency ?
                                                                        item.option.product.supplier.currency.code : '' }}
                                                                    {{ item.rate_day.net }} unconverted rate)
                                                                </label>
                                                            </small>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-else>
                                                    <small>No rate found for this period</small>
                                                </v-list-item>
                                            </td>
                                            <!-- GROSS RATE-->
                                            <td>
                                                <v-list-item v-if="item.rate_day">
                                                    <v-list-item-content
                                                        :set="rate = item.rate_day.gross * (days > 0 ? days : 1) * item.count"
                                                        :set2="converted_rate = item.rate_day.gross * conversion * (days > 0 ? days : 1) * item.count">
                                                        <v-list-item-title>
                                                            <small>
                                                                <b>{{ itinerary.currency.code }} </b>
                                                                <label
                                                                    v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                    <b>{{ rate.toFixed(2) }}</b>
                                                                </label>
                                                                <label v-else>
                                                                    <b>{{ converted_rate.toFixed(2) }}</b>
                                                                </label>
                                                            </small>
                                                            <v-tooltip
                                                                v-if="item.status_id == 3 && $date(item.confirmed_at).isBefore($date('2024-12-21'), 'day')"
                                                                top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <label v-bind="attrs" v-on="on">
                                                                        –
                                                                    </label>
                                                                </template>
                                                                <small>
                                                                    <h2>Using initial night rates</h2>
                                                                    Booking already confirmed before 12-21-2024
                                                                </small>
                                                            </v-tooltip>
                                                            <v-tooltip v-if="item.rate_days.length > 0" top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <label v-bind="attrs" v-on="on"
                                                                        :style="item.rate_days.filter(x =>
                                                                            x.day == $date(item.check_in_date).format('dddd'))[0].net == item.rate_day.net
                                                                            ? 'color:grey;' : item.rate_days.filter(x =>
                                                                                x.day == $date(item.check_in_date).format('dddd'))[0].net > item.rate_day.net ? 'color:blue;' : 'color:red;'">
                                                                        *
                                                                    </label>
                                                                </template>
                                                                <small>
                                                                    <h2>Using per night rates</h2>
                                                                    Initial night rates:
                                                                    <br>
                                                                    <b style="font-weight:800;">
                                                                        {{ itinerary.currency.code }}
                                                                        <label
                                                                            v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                            {{
                                                                                parseFloat(item.rate_days.filter(x =>
                                                                                    x.day ==
                                                                                    $date(item.check_in_date).format('dddd'))
                                                                                [0].gross * (days > 0 ? days : 1) *
                                                                                    item.count).toFixed(2)
                                                                            }}
                                                                        </label>
                                                                        <label v-else>
                                                                            {{
                                                                                parseFloat(item.rate_days.filter(x =>
                                                                                    x.day ==
                                                                                    $date(item.check_in_date).format('dddd'))
                                                                                [0].gross * conversion * (days > 0 ? days :
                                                                                    1) *
                                                                                    item.count).toFixed(2)
                                                                            }}
                                                                        </label>
                                                                    </b>
                                                                    <br>
                                                                    {{ itinerary.currency.code }}
                                                                    <label
                                                                        v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                        {{
                                                                            parseFloat(item.rate_days.filter(x =>
                                                                                x.day ==
                                                                                $date(item.check_in_date).format('dddd'))
                                                                            [0].gross).toFixed(2)
                                                                        }}
                                                                    </label>
                                                                    <label v-else>
                                                                        {{
                                                                            parseFloat(item.rate_days.filter(x =>
                                                                                x.day ==
                                                                                $date(item.check_in_date).format('dddd'))
                                                                            [0].gross * conversion).toFixed(2)
                                                                        }}
                                                                    </label>
                                                                    per count per night
                                                                </small>
                                                            </v-tooltip>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <small>
                                                                {{ itinerary.currency.code }}
                                                                <label
                                                                    v-if="item.option.product.supplier.currency_id == itinerary.currency_id">
                                                                    {{ item.rate_day.gross }}
                                                                </label>
                                                                <label v-else>
                                                                    {{ (item.rate_day.gross * conversion).toFixed(2) }}
                                                                </label>
                                                                <br>*
                                                                <label
                                                                    v-if="item.option.product.supplier.service_type_id == 1">
                                                                    {{ (days > 0 ? days : 1) }} night<label
                                                                        v-if="days > 1">s</label>
                                                                </label>
                                                                <label v-else>
                                                                    {{ (days > 0 ? days : 1) }} day<label
                                                                        v-if="days > 1">s</label>
                                                                </label>
                                                                <br>* {{ item.count }} count
                                                                <br>
                                                                <label style="color:grey;"
                                                                    v-if="item.option.product.supplier.currency_id != itinerary.currency_id">
                                                                    ({{ item.option.product.supplier.currency ?
                                                                        item.option.product.supplier.currency.code : '' }}
                                                                    {{ item.rate_day.gross }} unconverted rate)
                                                                </label>
                                                            </small>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-else>
                                                    <small>No rate found for this period</small>
                                                </v-list-item>
                                            </td>
                                            <td>
                                                {{ item.rate_day ? item.rate_day.markup : 0 }}%
                                            </td>
                                            <td v-if="show_occupancy">
                                                <v-text-field v-model="item.occupancy"
                                                    @change="updateBooking(item, false)" type="number" min="1" dense
                                                    outlined style="height:20px;"></v-text-field>
                                                <v-checkbox v-model="item.is_shared"
                                                    @change="updateBooking(item, false)" label="Shared"></v-checkbox>
                                            </td>
                                            <td>
                                                <div style="display:flex;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn @click="openCustomRateDialog(item)" v-bind="attrs"
                                                                v-on="on" icon :disabled="item.rate_day == null"
                                                                class="mr-1">
                                                                <v-icon size="35">mdi-cash-edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <small>
                                                            Rate settings
                                                        </small>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn @click="openUpdateNotesDialog(item)" v-bind="attrs"
                                                                v-on="on" icon class="mr-1">
                                                                <v-icon size="35">mdi-notebook</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <small>
                                                            View/edit notes
                                                        </small>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn @click="bookings_to_hide.push(item.id)"
                                                                v-bind="attrs" v-on="on" icon>
                                                                <v-icon size="35">mdi-eye-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <small>
                                                            Hide booking
                                                        </small>
                                                    </v-tooltip>
                                                </div>
                                            </td>
                                            <td>
                                                <v-select :items="get_booking_payment_statuses"
                                                    v-model="item.payment_status_id" @change="updateBooking(item)"
                                                    item-text="status" item-value="id" chips>
                                                    <template #selection="{ item }">
                                                        <v-chip :color="getColor(item.id)" style="cursor:pointer;">
                                                            {{ item.status }}
                                                        </v-chip>
                                                    </template>
                                                </v-select>
                                            </td>
                                        </tr>
                                    </template>
                                    <template slot="body.append">
                                        <tr
                                            v-if="itinerary.service_charge != null && itinerary.service_charge > 0 && bookings_to_hide.indexOf(-1) == -1">
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <v-list-item
                                                    :style="service_charge_selected == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <label>Service Charge</label>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <small>
                                                                <strong>{{ itinerary.currency.code }}</strong> {{
                                                                    itinerary.service_charge }}
                                                            </small>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </td>
                                            <td></td>
                                            <td style="text-align:right;">
                                                <v-btn icon class="mt-3"
                                                    @click="bookings_to_hide.push(-1)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            v-if="itinerary.orr != null && itinerary.orr > 0 && bookings_to_hide.indexOf(-2) == -1">
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <v-list-item
                                                    :style="orr_selected == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <label>ORR</label>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <small>
                                                                <strong>{{ itinerary.currency.code }}</strong> {{
                                                                    itinerary.orr }}
                                                            </small>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                            </td>
                                            <td></td>
                                            <td style="text-align:right;">
                                                <v-btn icon class="mt-3"
                                                    @click="bookings_to_hide.push(-2)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <strong>Net Total:<br>
                                                    {{ itinerary.currency.code }}
                                                    {{ net | formatNumber }}
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>Gross Total:<br>
                                                    {{ itinerary.currency.code }}
                                                    {{ gross | formatNumber }}
                                                </strong>
                                            </td>
                                            <td style="width:200px;">
                                                <strong>
                                                    Margin:<br>
                                                    {{ itinerary.currency.code }} {{ gross - net | formatNumber }}
                                                    ({{ ((gross - net) / gross * 100).toFixed(2) }}%)
                                                </strong>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-app>
                        </section>
                        <SharedCosts :show_occupancy="show_occupancy" :bookings="bookings" :itinerary="itinerary" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="update_notes_dialog" persistent max-width="600">
            <v-card v-if="selected_booking">
                <v-card-title>
                    <label>Update notes</label>
                </v-card-title>
                <v-divider />
                <v-card-text class="mt-5">
                    <wysiwyg v-model="selected_booking.notes" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined class="pl-3 pr-3" rounded @click="closeUpdateNotesDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                    <v-btn color="primary" class="pl-8 pr-8" rounded @click="updateBooking(selected_booking)">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ViewDocumentsDialog :view_documents_dialog="view_documents_dialog" :itinerary="itinerary"
            :document_type="document_type" @close="closeViewDocumentsDialog" />
        <CustomRateDialog v-if="selected_rate" :custom_rate_dialog="custom_rate_dialog" :selected_rate="selected_rate"
            :selected_rate_period="selected_rate_period" :selected_rate_period_days="selected_rate_period_days"
            :selected_booking="selected_booking" @close="closeCustomRateDialog" @saveCustomRate="saveCustomRate"
            @deleteCustomRate="deleteCustomRate" />
        <SupplierDetailsDialog v-if="selected_booking" :supplier_details_dialog="supplier_details_dialog"
            :selected_supplier="selected_booking.option.product.supplier" @close="closeSupplierDetailsDialog" />
        <SupplierEmailDialog v-if="supplier_email_dialog" :supplier_email_type="supplier_email_type"
            :supplier_email_dialog="supplier_email_dialog" :itinerary="itinerary" :grouped_bookings="grouped_bookings"
            @close="closeSupplierEmailDialog" @sendSupplierEmails="sendSupplierEmails" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'
import SharedCosts from './components/SharedCosts.vue'
import CustomRateDialog from './dialogs/CustomRateDialog.vue'
import ViewDocumentsDialog from '../dialogs/ViewDocumentsDialog.vue'
import SupplierDetailsDialog from '../dialogs/SupplierDetailsDialog.vue'
import SupplierEmailDialog from '../booking/dialogs/SupplierEmailDialog.vue'
export default {
    components: {
        SharedCosts, CustomRateDialog, ViewDocumentsDialog, SupplierDetailsDialog, SupplierEmailDialog
    },
    props: [
        'itinerary', 'loading_bookings', 'bookings', 'conversion', 'net', 'gross', 'gross_without_orr'
    ],
    data() {
        return {
            app_url: null,
            pricing_table_header: [
                { text: '', width: '1px' },
                { text: 'Booking ID', value: 'id', sortable: true, width: '1px' },
                { text: 'Item', value: 'option.product.supplier.supplier_name', sortable: true, width: '350px' },
                { text: 'Type', value: 'option.product.supplier.service_type_id', sortable: true, width: '50px' },
                { text: 'Dates', value: 'check_in_date', width: '100px' },
                { text: 'Net', value: 'net', sortable: false, width: '50px' },
                { text: 'Gross', value: 'gross', sortable: false, width: '50px' },
                { text: 'Markup', value: 'markup', sortable: false, width: '50px' },
                { text: '', value: 'action', sortable: false, width: '150px' },
                { text: 'Status', value: 'pricing_status_id', sortable: true, width: '200px' },
            ],
            pricing_table_header_occupancy: [
                { text: '', width: '1px' },
                { text: 'Booking ID', value: 'id', sortable: true, width: '1px' },
                { text: 'Item', value: 'option.product.supplier.supplier_name', sortable: true, width: '350px' },
                { text: 'Type', value: 'option.product.supplier.service_type_id', sortable: true, width: '50px' },
                { text: 'Dates', value: 'check_in_date', width: '100px' },
                { text: 'Net', value: 'net', sortable: false, width: '50px' },
                { text: 'Gross', value: 'gross', sortable: false, width: '50px' },
                { text: 'Markup', value: 'markup', sortable: false, width: '50px' },
                { text: 'Occupancy', value: 'occupancy', sortable: true, width: '50px' },
                { text: '', value: 'action', sortable: false, width: '150px' },
                { text: 'Status', value: 'pricing_status_id', sortable: true, width: '200px' },
            ],
            service_charge_selected: false,
            orr_selected: false,
            bookings_to_hide: [],
            custom_rate_dialog: false,
            selected_rate: null,
            selected_rate_period: null,
            selected_rate_period_days: [],
            edit_net_price_dialog: false,
            selected_bookings: [],
            selected_booking: null,
            supplier_email_dialog: false,
            supplier_email_type: null,
            grouped_bookings: [],
            supplier_details_dialog: false,
            update_notes_dialog: false,
            show_occupancy: false,
            sort_by: 'check_in_date',
            sort_desc: false,
            view_documents_dialog: false,
            document_type: 'admin pricing',
            selected_booking_id: null,
        }
    },
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_booking_statuses: 'itineraryV2/get_booking_statuses',
            get_booking_payment_statuses: 'itineraryV2/get_booking_payment_statuses',
        }),
        margin_percent: {
            get() {
                const gross = parseFloat(this.gross_without_orr) + parseFloat(this.itinerary.orr);
                if (gross === 0) return 0;
                return parseFloat((((gross - this.net) / gross) * 100).toFixed(2));
            },
            set(newVal) {
                const mp = parseFloat(newVal);
                const gwo = parseFloat(this.gross_without_orr);
                const n = parseFloat(this.net);
                const denominator = mp - 100;

                if (denominator !== 0) { // Prevent division by zero
                    let calculatedOrr = ((gwo * (100 - mp)) - (n * 100)) / denominator;

                    // Handle potential invalid calculations
                    if (isNaN(calculatedOrr) || !isFinite(calculatedOrr)) {
                        calculatedOrr = 0;
                    } else {
                        calculatedOrr = parseFloat(calculatedOrr.toFixed(2));
                    }

                    this.itinerary.orr = calculatedOrr;
                } else {
                    // If margin_percent is exactly 100, set orr to gross_without_orr - net
                    this.itinerary.orr = parseFloat((gwo - n).toFixed(2));
                }
            },
        },
        bookingsCsv() {
            let csv_bookings = []
            this.bookings.forEach(booking => {
                let days = (this.itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                    ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                    : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')

                let selected_net = 0
                let selected_gross = 0
                if (booking.rate_day) {
                    let net = booking.rate_day.net * (days > 0 ? days : 1) * booking.count
                    let converted_net = booking.rate_day.net * this.conversion * (days > 0 ? days : 1) * booking.count

                    let gross = booking.rate_day.gross * (days > 0 ? days : 1) * booking.count
                    let converted_gross = booking.rate_day.gross * this.conversion * (days > 0 ? days : 1) * booking.count

                    if (booking.option.product.supplier.currency_id == this.itinerary.currency_id) {
                        selected_net = net
                        selected_gross = gross
                    }
                    else {
                        selected_net = converted_net
                        selected_gross = converted_gross
                    }
                }

                let formatted_booking = {
                    BookingID: booking.id,
                    Supplier: booking.option.product.supplier.supplier_name,
                    Product: booking.option.product.name,
                    Option: booking.option.name,
                    Type: booking.option.product.supplier.service_type_id == 1 ? 'Accommodation' : booking.option.product.supplier.service_type_id == 2 ? 'Activity' : booking.option.product.supplier.service_type_id == 3 ? 'Transporatation' : 'Other',
                    StartDate: booking.check_in_date,
                    EndDate: booking.check_out_date,
                    Net: selected_net,
                    Gross: selected_gross,
                    Markup: booking.rate_day ? booking.rate_day.markup : 0,
                }
                csv_bookings.push(formatted_booking)
            })
            return csv_bookings
        },
    },
    methods: {
        async refreshBookings(message = false, success = false) {
            this.$emit('refreshBookings', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async saveDocument() {
            this.$toast('Saving, please wait...')
            const element = document.getElementById('pricing-table')
            html2pdf().set({
                margin: 0.2,
                filename: this.itinerary.itinerary_name + ' Pricing.pdf',
                image: { type: 'jpeg' },
                html2canvas: { scale: 1, useCORS: true },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' }
            }).from(element).toPdf().get('pdf').then((pdf) => {
                const blob = pdf.output('blob')

                const formData = new FormData()
                formData.append('itinerary_id', this.itinerary.id)
                formData.append('type', 'admin pricing')
                formData.append('file', blob, this.itinerary.itinerary_name + ' Pricing.pdf')

                this.$axios.post('v2/itineraries/create_itinerary_document', formData)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.$toast.success(data.message)
                            this.$emit('refreshItinerary')
                        }
                        else {
                            this.$toast.error(data.message)
                        }
                    })
            })
        },
        openViewDocumentsDialog() {
            this.view_documents_dialog = true
        },
        closeViewDocumentsDialog() {
            this.view_documents_dialog = false
        },
        selectBooking(booking) {
            let index = this.selected_bookings.findIndex((item) => item.id == booking.id)
            if (index == -1) {
                this.selected_bookings.push(booking)
            }
            else {
                this.selected_bookings.splice(index, 1)
            }
        },
        openSupplierEmailDialog(type) {
            this.supplier_email_type = type
            const supplierMap = {}
            let indexCounter = 0
            this.grouped_bookings = []
            this.selected_bookings.forEach((booking) => {
                const supplierId = booking.option.product.supplier.id
                if (supplierMap[supplierId] === undefined) {
                    supplierMap[supplierId] = indexCounter
                    this.grouped_bookings[indexCounter] = []
                    indexCounter++
                }
                const groupIndex = supplierMap[supplierId]
                this.grouped_bookings[groupIndex].push(booking)
            })

            this.grouped_bookings.forEach(supplier_bookings => {
                let total = parseFloat(0)
                let booking_ids = ''
                supplier_bookings[0].email = 'Hi ' + supplier_bookings[0].option.product.supplier.supplier_name + ',<br><br>'
                supplier_bookings[0].email += 'We have sent you a payment for the below group through online banking today. '
                supplier_bookings[0].email += 'If your bank is the Bank of Ireland, you will receive the payment right away. '
                supplier_bookings[0].email += 'If you are with a bank other than the Bank of Ireland, it will take 2 to 3 working days for the payment to reach your account. '
                supplier_bookings[0].email += 'The account name should appear as DG Ventures Ltd / ' + this.itinerary.company.company_name + '.<br><br>'

                supplier_bookings[0].email += '<table style="width:100%;"><tr>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Arrival Date</th>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Booking Name</th>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Amount Paid</th></tr>'
                supplier_bookings.forEach((booking) => {
                    booking_ids += booking.id + ','
                    let days = (this.itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    let amount = (booking.rate_day ? booking.rate_day.net : 0) * (days > 0 ? days : 1) * booking.count
                    total += parseFloat(amount)

                    supplier_bookings[0].email += '<tr><td style="padding:10px;">' + booking.check_in_date + '</td>'
                    supplier_bookings[0].email += '<td style="padding:10px;">' + this.itinerary.itinerary_name + ' - ' + this.itinerary.itinerary_number + ' - ' + booking.id + '</td>'
                    supplier_bookings[0].email += '<td style="padding:10px;">' + booking.option.product.supplier.currency.symbol + parseFloat(amount).toFixed(2) + '</td></tr>'
                })
                supplier_bookings[0].email += '<tr><td></td><td></td><td></td></tr>'
                supplier_bookings[0].email += '<tr><td></td><td style="padding:10px;"><b>Total Amount Paid</b></td><td style="padding:10px;"><b>' + supplier_bookings[0].option.product.supplier.currency.symbol + parseFloat(total).toFixed(2) + '</b></td></tr></table><br>'

                supplier_bookings[0].email += '<div style="text-align:center;">'
                let confirm_link = this.app_url + 'confirm_payment/' + booking_ids
                let confirm_link_style = 'background-color:#1976D2;color:white!important;border-radius:8px;padding: 20px 40px;font-weight:600;font-size:16px;'
                supplier_bookings[0].email += '<br><br><br><a href="' + confirm_link + '" style="' + confirm_link_style + '">CONFIRM</a><br><br><br>'
                supplier_bookings[0].email += '</div>'

                supplier_bookings[0].email += '<label>Kindly please confirm the receipt of the same. Should you have any questions please do not hesitate to contact me.</label><br><br>'
                supplier_bookings[0].email += '<label>Thank you,</label><br><br>'
            })
            this.supplier_email_dialog = true
        },
        closeSupplierEmailDialog() {
            this.supplier_email_dialog = false
        },
        async sendSupplierEmails(bookings_to_send) {
            let payload = {
                company: this.itinerary.company,
                itinerary_name: this.itinerary.itinerary_name,
                grouped_bookings: bookings_to_send,
                supplier_email_type: this.supplier_email_type,
            }
            await this.$axios.post('v2/itineraries/send_supplier_emails', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeSupplierEmailDialog()
                        this.selected_bookings = []
                        this.refreshBookings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async saveCharges() {
            let payload = {
                service_charge: parseFloat(this.itinerary.service_charge),
                orr: parseFloat(this.itinerary.orr),
            }
            await this.$axios.post(`v2/itineraries/update_itinerary/${this.itinerary.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.refreshBookings()
                    }
                })
        },
        async updateBooking(item, success = true) {
            let payload = {
                ...item,
            }
            await this.$axios.post(`v2/itineraries/update_booking/${this.itinerary.id}/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        if (success) {
                            this.$toast.success(data.message)
                        }
                        this.closeUpdateNotesDialog()
                    }
                })
        },
        openCustomRateDialog(item) {
            console.log(item)
            this.custom_rate_dialog = true
            this.selected_rate = item.rate_day
            this.selected_rate_period = item.rate_period
            this.selected_rate_period_days = item.rate_days
            this.selected_booking_id = item.id
            this.selected_booking = item
        },
        closeCustomRateDialog() {
            this.custom_rate_dialog = false
            this.selected_rate = false
            this.selected_rate_period = null
            this.selected_rate_period_days = null
            this.selected_booking_id = null
            this.selected_booking = null
        },
        async saveCustomRate(updated_rate) {
            if (updated_rate.itinerary_id == null) {
                updated_rate.itinerary_id = this.itinerary.id
                updated_rate.booking_id = this.selected_booking_id
                let payload = {
                    ...updated_rate,
                }
                await this.$axios.post('v2/suppliers/create_custom_rate', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.success(data.message)
                            this.closeCustomRateDialog()
                            this.refreshBookings()
                        }
                    })
            }
            else {
                let payload = {
                    ...updated_rate,
                }
                await this.$axios.post(`v2/suppliers/update_custom_rate/${updated_rate.id}`, payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.success(data.message)
                            this.closeCustomRateDialog()
                            this.refreshBookings()
                        }
                    })
            }
        },
        async deleteCustomRate(updated_rate) {
            await this.$axios.delete(`v2/suppliers/delete_custom_rate/${updated_rate.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCustomRateDialog()
                        this.refreshBookings()
                    }
                })
        },
        openUpdateNotesDialog(data) {
            this.update_notes_dialog = true
            this.selected_booking = data
        },
        closeUpdateNotesDialog() {
            this.update_notes_dialog = false
            this.selected_booking = null
        },
        openSupplierDetailsDialog(item) {
            this.supplier_details_dialog = true
            this.selected_booking = item
        },
        closeSupplierDetailsDialog() {
            this.supplier_details_dialog = false
            this.selected_booking = null
        },
        printPDF() {
            this.$toast.info('Printing...', { timeout: 5000 })
            html2pdf(document.getElementById('pricing-table'), {
                margin: 0.2,
                filename: this.itinerary.itinerary_name + '.pdf',
                image: { type: 'jpeg' },
                html2canvas: { scale: 1, useCORS: true },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' }
            })
        },
        getColor(status_id) {
            if (status_id != null) {
                switch (status_id) {
                    case 1:
                        return 'warning'
                    case 2:
                        return 'primary'
                    case 3:
                        return 'success'
                    default:
                        return 'primary'
                }
            } else {
                return 'primary'
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(div),
:deep(tr) {
    page-break-inside: avoid;
}

@media print {
    #pricing-table {
        transform: scale(0.8);
        /* Adjust this to fit the table */
        transform-origin: top left;
        width: 100%;
    }
}

:deep(.no-rates) {
    background-color: rgb(240, 172, 172);
}

:deep(.no-rates:hover) {
    background-color: rgb(240, 172, 172) !important;
}

.selected,
.selected:hover {
    background-color: #BBE0F9 !important;
}
</style>