<template>
    <v-dialog :value="view_vouchers_dialog" persistent max-width="1000px">
        <v-card elevation="0">
            <v-card-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <section id="voucher-list">
                <v-app>
                    <v-card-text v-for="(item, itemindex) in selected_bookings" :key="itemindex" elevation="0"
                        class="mt-5" style="color:black;">
                        <v-row style="border:solid black;border-width: 1px 1px 0px 1px;">
                            <v-col cols="6">
                                <v-row style="padding:5px 10px;">
                                    <v-col cols="3" style="padding:0px;">
                                        <b>Voucher for:</b>
                                    </v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        <b>{{ item.option.product.supplier.supplier_name }}</b>
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.option.product.supplier.address }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.option.product.supplier.phone }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.option.product.supplier.website }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;margin-top:10px;">
                                        <b>Client name:</b>
                                    </v-col>
                                    <v-col cols="9" style="padding:0px;margin-top:10px;">
                                        <b style="background-color:#D3E7FF;" v-if="itinerary.travellers[0]">
                                            <label>
                                                {{ itinerary.travellers[0].first_name }}
                                                {{ itinerary.travellers[0].last_name }}
                                            </label>
                                        </b>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" style="padding:5px 10px;">
                                <v-img eager style="object-fit:contain;text-align:left;"
                                    :src="itinerary.company.base64_logo" />
                            </v-col>
                        </v-row>
                        <v-row style="border:solid black;border-width: 0px 1px 0px 1px;">
                            <v-col cols="5">VOUCHER INCLUDES</v-col>
                            <v-col cols="2">START TIME</v-col>
                            <v-col cols="2">END TIME</v-col>
                            <v-col>NTS/DYS</v-col>
                            <v-col>QTY</v-col>
                            <v-col>REF</v-col>
                        </v-row>
                        <v-row
                            :set="days = (itinerary.count_transpo_end_date == 1 && item.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                                ? $date(item.check_out_date).diff($date(item.check_in_date), 'd') + 1 : $date(item.check_out_date).diff($date(item.check_in_date), 'd')"
                            style="border:solid black;border-width: 1px 1px 1px 1px;">
                            <v-col cols="5">
                                {{ item.option.product.name }}
                            </v-col>
                            <v-col cols="2">
                                {{ $date(item.check_in_date).format('DD MMM YY') }}<br>{{ item.check_in_time ?
                                    $date(item.check_in_date + item.check_in_time).format('hh:mm A') : null }}
                            </v-col>
                            <v-col cols="2">
                                {{ $date(item.check_out_date).format('DD MMM YY') }}<br>{{ item.check_out_time ?
                                    $date(item.check_out_date + item.check_out_time).format('hh:mm A') : null }}
                            </v-col>
                            <v-col>
                                {{ days }}
                            </v-col>
                            <v-col>
                                {{ item.count }}
                            </v-col>
                            <v-col>
                                {{ item.booking_reference_number }}
                            </v-col>
                        </v-row>
                        <v-row style="border:solid black;border-width: 0px 1px 0px 1px;">
                            <v-col cols="12" style="height:100px;">
                                NOTES:
                                <br>
                                <label v-if="item.option.product.supplier.auto_add_notes">
                                    {{ item.option.product.supplier.notes }}
                                </label>
                                <label v-else>
                                    {{ item.instructions }}
                                </label>
                            </v-col>
                        </v-row>
                        <v-row
                            style="border-bottom:solid 1px black;border-left:solid 1px black;border-right:solid 1px black;">
                            <v-col cols="3">
                                <b>Voucher no:</b> {{ item.id }}
                            </v-col>
                            <v-col cols="3">
                                <b>Itinerary no:</b> {{ itinerary.itinerary_number }}
                            </v-col>
                            <v-col cols="3">
                                <label v-if="itinerary.contributors.length > 0 && itinerary.contributors.filter(x => x.user.user_role_id == 2).length > 0">
                                    <b>Issued by:</b> {{ (itinerary.contributors.filter(x => x.user.user_role_id == 2)[0]) ?
                                        itinerary.contributors.filter(x => x.user.user_role_id == 2)[0].user.name : null }}
                                </label>
                            </v-col>
                            <v-col cols="3">
                                <b>Issue date:</b> {{ $date(Date.now()).format('DD MMMM, YYYY') }}
                            </v-col>
                        </v-row>
                        <br class="html2pdf__page-break" v-if="itemindex < selected_bookings.length - 1">
                    </v-card-text>
                </v-app>
            </section>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'view_vouchers_dialog', 'itinerary', 'selected_bookings',
    ],
    data() {
        return {
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped lang="scss"></style>