<template>
    <v-container fluid style="background-color: #f4f4f5 !important">
        <v-row class="mt-5" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Options</strong></small>
                </v-card-title>
                <v-card-subtitle>
                    Add the ways this product can be sold here; e.g. Infant, Child, Adult
                    or Single, Twin, Double or 1-5 Days
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex align-center">
                                <v-text-field v-model="new_option.name" label="Add an Option" outlined dense
                                    @keyup.enter="createOption()">
                                    <template slot="append">
                                        <small class="mt-2">Press ENTER to save</small>
                                    </template>
                                </v-text-field>
                                <v-checkbox v-model="create_zero_rate" label="Create default 0 rates" class="mb-5 ml-3" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-checkbox v-model="show_archived" label="Show Archived"></v-checkbox>
                    <v-list>
                        <v-list-item-group v-for="(option, index) in product.options" :key="index">
                            <v-list-item v-if="option.is_archived == 0 || show_archived" two-line
                                style="border-top: 1px solid gray; border-bottom: 1px solid gray" class="mt-2">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ option.name }}
                                        <label v-if="option.is_archived" style="font-size:14px;color:grey;">
                                            (archived)</label>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row class="align-center">
                                        <v-checkbox v-model="option.is_free" label="Free" @change="updateOption(option)"
                                            class="pr-3"></v-checkbox>
                                        <v-btn icon>
                                            <v-icon @click="open_rates(option)"
                                                color="grey lighten-1">mdi-currency-usd</v-icon>
                                        </v-btn>
                                        <v-menu left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="openUpdateOptionDialog(option)">
                                                    <v-list-item-title>
                                                        <v-icon class="mr-3">mdi-pen</v-icon>
                                                        <strong>Edit</strong>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-title @click="duplicateOption(option)"
                                                        style="cursor: pointer;">
                                                        <v-icon class="mr-3">
                                                            mdi-content-duplicate
                                                        </v-icon>
                                                        <strong>Duplicate</strong>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="archiveOption(option)"
                                                    v-if="option.is_archived == 0">
                                                    <v-list-item-title>
                                                        <v-icon class="mr-3">
                                                            mdi-archive
                                                        </v-icon>
                                                        <strong>Archive</strong>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="restoreOption(option)" v-else>
                                                    <v-list-item-title>
                                                        <v-icon class="mr-3">
                                                            mdi-archive
                                                        </v-icon>
                                                        <strong>Restore</strong>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-row>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="60%" overlay-color="#f4f4f5" overlay-opacity="1">
            <v-btn fab @click="close_modal" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ dialog_title }}</span>
                    <v-spacer />
                    <small class="mr-3">Hide expired rates</small>
                    <v-switch inset dense v-model="hide_expired_rates" @change="toggle_expire_rates"></v-switch>
                    <v-btn v-if="mode === 'rates' && rates === false" color="#588BAD" rounded class="pl-10 pr-10" dark
                        style="text-transform: none !important; color: white" @click="rates = true">
                        Create rate period
                    </v-btn>
                </v-card-title>
                <v-card-subtitle v-if="mode === 'rates'">
                    Create a rate period and enter the applicable rates
                </v-card-subtitle>
                <v-card-text>
                    <div v-if="!rates && mode === 'rates'">
                        <v-expansion-panels v-for="(itemoptionrate, itemoptionrateindex) in ratelist"
                            :key="itemoptionrateindex" class="rounded-0" outlined>
                            <v-expansion-panel>
                                <div v-if="!itemoptionrate.expired">
                                    <v-expansion-panel-header>
                                        {{ itemoptionrate.start_date }} - {{ itemoptionrate.end_date }}
                                        <div style="text-align:right;">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="edit_option_rate(itemoptionrate)">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-3">
                                                                mdi-pen
                                                            </v-icon>
                                                            <strong>Edit</strong>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="duplicate_rates(itemoptionrate)">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-3">
                                                                mdi-content-duplicate
                                                            </v-icon>
                                                            <strong>Duplicate</strong>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="archive_rates(itemoptionrate)">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-3">
                                                                mdi-archive
                                                            </v-icon>
                                                            <strong>Archive</strong>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table :headers="rate_headers"
                                            :items="itemoptionrate.supplierrates.filter(x => x.itinerary_id == null)">
                                            <template v-slot:item="{ item }">
                                                <tr>
                                                    <td>{{ item.day }}</td>
                                                    <td>{{ item.net }}</td>
                                                    <td>{{ item.markup }}</td>
                                                    <td>{{ item.gross }}</td>
                                                    <td>{{ item.commission }}</td>
                                                    <td>
                                                        <v-btn icon @click="edit_rate(item, itemoptionrate)">
                                                            <v-icon small>mdi-pen</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-content>
                                </div>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                    <v-row v-if="mode === 'edit'">
                        <v-col cols="12">
                            <v-text-field v-model="f.name" label="Add a Option *" outlined dense>
                            </v-text-field>
                            <v-card v-if="dialog_title === 'Edit option name'" elevation="0">
                                <v-card-text>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
                                            <v-img
                                                :src="image === null ? 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg' : image"
                                                :aspect-ratio="16 / 9">
                                            </v-img>
                                            <v-fade-transition>
                                                <v-overlay v-if="hover" absolute color="#036358">
                                                    <v-card elevation="0" color="transparent">
                                                        <v-card-title class="justify-center">
                                                            <v-row align="center" justify="center">
                                                                <v-btn icon style="cursor: pointer;"
                                                                    @click="insert_image(edit)">
                                                                    <v-icon color="black">mdi-pen</v-icon>
                                                                </v-btn>
                                                                <v-btn class="ml-1" style="cursor: pointer;" icon
                                                                    @click="remove_image(edit)">
                                                                    <v-icon color="black">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text v-if="has_to_upload_image">
                                                            <v-file-input show-size truncate-length="15"
                                                                v-model="image_to_upload" style="width: 250px;" outlined
                                                                label="click here to change"></v-file-input>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-overlay>
                                            </v-fade-transition>
                                        </v-card>
                                    </v-hover>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="rates">
                        <v-col cols="12">
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="date" dense outlined label="Start Date *" v-model="r.start_date">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="date" dense outlined label="End Date *" v-model="r.end_date">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <span v-if="rates" class="text-h5">Enter Rates for:</span>
                    <div v-if="rates">
                        <v-row v-for="(item, index) in drates" :key="item.name">
                            <v-col cols="4">
                                <v-text-field v-model="item.day" dense outlined label="Day" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="item.net" type="number" dense outlined label="Net *"
                                    @keyup="computerates(item, index)">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="item.markup" type="number" dense outlined label="Markup % *"
                                    @keyup="computerates(item, index)">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="item.gross" type="number" dense outlined label="Gross *"
                                    readonly>
                                    {{ item.net * item.markup }}
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="item.commission" type="number" dense outlined
                                    label="Commission *" readonly>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn v-if="mode === 'edit'" color="#588BAD" dark rounded @click="update_supplier_product_option">
                        Update
                    </v-btn>
                    <v-btn v-if="rates" color="#588BAD" dark rounded @click="save_supplier_product_rate()">
                        Save
                    </v-btn>
                    <v-btn v-if="rates" color="#588BAD" dark rounded @click="rates = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="view_rate_dialog" persistent width="720">
            <v-card>
                <v-card-title>
                    View Rates for {{ get_selected_service.name }}
                </v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-group v-for="(item, index) in get_rates_view" :key="index" no-action>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.start_date }} - {{ item.end_date }}</v-list-item-title>
                                    <!-- {{ get_edit_rates }} -->
                                </v-list-item-content>
                            </template>
                            <v-data-table :headers="header_rates_child"
                                :items="item.supplierrates.filter(x => x.itinerary_id == null)"
                                :expanded.sync="expandedchild" single-expand>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            {{ item.day }}
                                        </td>
                                        <td>
                                            {{ item.net }}
                                        </td>
                                        <td>
                                            {{ item.gross }}
                                        </td>
                                        <td>
                                            {{ item.markup }}
                                        </td>
                                        <td>
                                            {{ item.commission }}
                                        </td>
                                        <td>
                                            <v-btn icon @click="edit_rate(item)">
                                                <v-icon small>mdi-pen</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-list-group>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="view_rate_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit_rate_dialog" persistent width="560">
            <v-card>
                <v-card-title>
                    <small>{{ get_edit_rates.day }}</small>
                    <v-spacer />
                    <v-icon @click="close_edit_rate_dialog()">
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="edit_rate_model.net" label="Net"
                        :hint="`Original Net: ${get_edit_rates.net}`" outlined dense type="number"
                        @keyup="computeeditrates(edit_rate_model)">
                    </v-text-field>
                    <v-text-field v-model="edit_rate_model.markup" label="Markup %"
                        :hint="`Original Markup: ${get_edit_rates.markup}`" outlined dense type="number"
                        @keyup="computeeditrates(edit_rate_model)">
                    </v-text-field>
                    <v-text-field v-model="edit_rate_model.gross" label="Gross"
                        :hint="`Original Gross ${get_edit_rates.gross}`" outlined dense readonly type="number">
                    </v-text-field>
                    <v-text-field v-model="edit_rate_model.commission" label="Commission"
                        :hint="`Original Commission ${get_edit_rates.commission}%`" outlined dense readonly
                        type="number">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close_edit_rate_dialog()">
                        <v-icon class="mr-1">
                            mdi-close
                        </v-icon>
                        cancel
                    </v-btn>
                    <v-btn class="pl-10 pr-10" color="primary" @click="save_edited_data()">
                        <v-icon class="mr-2">
                            mdi-content-save
                        </v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="child_data_dialog" persistent max-width="60%" overlay-color="#f4f4f5" overlay-opacity="1">
            <v-btn fab @click="child_data_dialog = false; child_data_model = null" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card-title>
                {{ get_add_option_children_parent_data.name }}
                <v-spacer />
                <v-icon @click="child_data_dialog = false; child_data_model = null">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-subtitle>
                Add 3rd party supplier
            </v-card-subtitle>
            <v-card-text>
                <v-text-field dense outlined v-model="child_data_model"
                    :placeholder="`add a child option for ${get_add_option_children_parent_data.name}`"
                    @keyup.enter="add_child_option()">
                    <template slot="append">
                        <small class="mt-2">Press ENTER to add</small>
                    </template>
                </v-text-field>
            </v-card-text>
        </v-dialog>
        <v-dialog v-model="show_child_data_dialog" persistent max-width="60%" overlay-color="#f4f4f5">
            <v-card>
                <v-card-title>
                    {{ get_child_data_option.name }}
                    <v-spacer />
                    <v-icon @click="show_child_data_dialog = false">
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-card-subtitle>
                    Show 3rd party supplier
                </v-card-subtitle>
                <v-card-text>
                    <v-text-field placeholder="Search here..." outlined dense prepend-inner-icon="mdi-magnify"
                        v-model="search_child_data">
                    </v-text-field>
                    <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(item, i) in get_child_item_data" :key="i">
                            <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card elevation="0">
                                    <v-card-title>
                                        Rate periods - {{ item.name }}
                                        <v-spacer />
                                        <v-btn color="primary" rounded small class="pr-10 pl-10"
                                            @click="show_dialog_rate_period_child(item)">
                                            create rate period
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        create a rate period and enter the applicable rates
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-expansion-panels accordion>
                                            <v-expansion-panel
                                                v-for="(item_child, index_child) in item.supplier_option_child_rate"
                                                :key="index_child">
                                                <v-expansion-panel-header>{{ item_child.name
                                                    }}</v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-data-table :headers="header_rates_child"
                                                        :items="item_child.supplier_option_child_rate_days">
                                                        <template v-slot:item="{ item }">
                                                            <tr>
                                                                <td>
                                                                    {{ item.day }}
                                                                </td>
                                                                <td>
                                                                    {{ item.net }}
                                                                </td>
                                                                <td>
                                                                    {{ item.gross }}
                                                                </td>
                                                                <td>
                                                                    {{ item.markup }}
                                                                </td>
                                                                <td>
                                                                    {{ item.commission }}
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </v-data-table>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-card-text>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="new_edit_rate_dialog" persistent max-width="60%">
            <v-card>
                <v-card-text>
                    <v-text-field v-model="rate_to_edit.name"></v-text-field>
                    <v-text-field type="date" dense outlined label="Start Date *" v-model="rate_to_edit.start_date">
                    </v-text-field>
                    <v-text-field type="date" dense outlined label="End Date *" v-model="rate_to_edit.end_date">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="new_edit_rate_dialog = false" text>Cancel</v-btn>
                    <v-btn @click="save_edited_rate">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PORatePeriods :value="rate_period_child" @close_child="close_child" @close_deep="close_deep" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PORatePeriods from './childcomponent/POrateperiods.vue'
export default {
    components: {
        PORatePeriods
    },
    props: ['supplier', 'product'],
    data: () => ({
        new_option: {
            name: null,
        },
        hide_expired_rates: false,
        loading: true,
        dialog_title: null,
        dialog: false,
        show_archived: false,
        edit: null,
        mode: null,
        rates: false,
        ratelist: null,
        image: null,
        has_to_upload_image: false,
        icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
        selectoption: null,
        image_to_upload: null,
        r: {
            name: '2024 Rate',
            start_date: null,
            end_date: null,
        },
        drates: [
            {
                day: "Monday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Tuesday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Wednesday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Thursday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Friday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Saturday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Sunday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
        ],
        f: {
            name: null,
        },
        showcode: 0,
        view_rate_dialog: false,
        expandedchild: [],
        header_rates_child: [
            {
                text: 'Day',
                sortable: false
            },
            {
                text: 'Net',
                sortable: false
            },
            {
                text: 'Gross',
                sortable: false
            },
            {
                text: 'Markup',
                sortable: false
            },
            {
                text: 'Commission',
                sortable: false
            },
            {
                text: '',
                sortable: false
            }
        ],
        edit_rate_dialog: false,
        edit_rate_model: {
            net: 0,
            markup: 0,
            gross: 0,
            commission: 0
        },
        rate_to_edit: {
            id: null,
            name: null,
            start_date: null,
            end_date: null,
        },
        new_edit_rate_dialog: false,
        child_data_dialog: false,
        child_data_model: null,
        show_child_data_dialog: false,
        search_child_data: null,
        rate_period_child: false,
        selected_option: null,
        rate_headers: [
            {
                text: 'Day'
            },
            {
                text: 'Net'
            },
            {
                text: 'Markup'
            },
            {
                text: 'Gross'
            },
            {
                text: 'Commission'
            },
            {
                text: ''
            }
        ],
        create_zero_rate: false,
    }),
    async mounted() {
    },
    created() { },
    computed: {
        ...mapGetters({
            get_rates_view: 'supplier/get_rates_view',
            get_rates: 'supplier_rates/get_rates',
            get_selected_service: 'supplier/get_selected_service',
            get_edit_rates: 'supplier/get_edit_rates',
            get_response: 'supplier/get_response',
            get_user: 'auth/get_user',
            get_add_option_children_parent_data: 'supplier/get_add_option_children_parent_data',
            get_child_data_option: 'supplier/get_child_data'
        }),
        get_child_item_data() {
            return this.$store.getters['supplier/get_child_item_data'](this.search_child_data)
        }
    },
    methods: {
        async createOption() {
            let payload = {
                name: this.new_option.name,
                supplier_prod_id: this.product.id,
                create_zero_rate: this.create_zero_rate,
            }
            await this.$axios.post('v2/suppliers/create_option', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$emit('getSupplier')
                    }
                })
            this.new_option.name = null
        },
        async updateOption(item) {
            console.log(item)
            let payload = {
                name: item.name,
                is_free: item.is_free,
            }
            await this.$axios.post(`v2/suppliers/update_option/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                })
        },
        async toggle_expire_rates() {
            if (this.hide_expired_rates == true) {
                this.ratelist = this.ratelist.filter(a => {
                    return this.$date(a.end_date).format('YYYY-MM-DD') >= this.$date().format('YYYY-MM-DD')
                })
            }
            else {
                await this.$axios.get(`suppliers/products/get_product_option_rates/${this.selectoption.id}`)
                    .then(({ data }) => {
                        if (data.response) {
                            this.ratelist = data.data
                            this.ratelist.forEach(rate => {
                                rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                            })
                        }
                    })
            }
        },
        close_edit_rate_dialog() {
            this.edit_rate_dialog = false
            this.edit_rate_model.net = 0
            this.edit_rate_model.markup = 0
            this.edit_rate_model.gross = 0
            this.edit_rate_model.commission = 0
        },
        computerates(item, index) {
            const a = item.net * (item.markup / 100);
            const gross = Number(item.net) + Number(a);
            this.drates[index].gross = Number(gross).toFixed(0);
            this.drates[index].commission = Number(item.markup / gross).toFixed(2);
        },
        computeeditrates(item) {
            const a = item.net * (item.markup / 100);
            const gross = Number(item.net) + Number(a);
            this.edit_rate_model.gross = Number(gross).toFixed(0);
            this.edit_rate_model.commission = Number(item.markup / gross).toFixed(2);
        },
        async duplicateOption(item) {
            console.log(item)
            await this.$axios.post('/suppliers/products/duplicate_product_option_v2', item)
                .then(() => {
                    this.$emit('getSupplier')
                })
        },
        openUpdateOptionDialog(item) {
            this.dialog_title = `Edit option name`;
            this.dialog = true;
            this.f.name = item.name;
            this.edit = item;
            this.mode = "edit";
            this.rates = false;
            this.image = item.image
        },
        edit_option_rate(item) {
            this.selected_option = item
            console.log(item)
            this.rate_to_edit.id = item.id
            this.rate_to_edit.name = item.name
            this.rate_to_edit.start_date = this.$date(item.start_date).format('YYYY-MM-DD')
            this.rate_to_edit.end_date = this.$date(item.end_date).format('YYYY-MM-DD')
            this.new_edit_rate_dialog = true
        },
        async save_edited_rate() {
            let payload = {
                id: this.rate_to_edit.id,
                name: this.rate_to_edit.name,
                start_date: this.rate_to_edit.start_date,
                end_date: this.rate_to_edit.end_date,
            }
            console.log(payload)
            await this.$axios.post('suppliers/products/update_rate', payload)
                .then(({ data }) => {
                    console.log(data)
                    this.getRates()
                    this.new_edit_rate_dialog = false
                })
        },
        edit_ratename(item) {
            this.dialog_title = `Edit Rates`;
            this.dialog = true;
            this.f.name = item.name;
            this.edit = item;
            this.mode = "edit rates";
            this.rates = true;
        },
        close_modal() {
            this.dialog_title = null;
            this.dialog = false;
            this.f.name = null;
            this.edit = null;
            this.mode = null;
            this.rates = false;
        },
        async open_rates(item) {
            this.dialog_title = `Rate periods - ${item.name}`;
            this.dialog = true;
            this.mode = "rates";
            this.selectoption = item;

            await this.$axios.get(`suppliers/products/get_product_option_rates/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.ratelist = data.data;
                        this.ratelist.forEach(rate => {
                            rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                        })
                    }
                });
        },
        async save_supplier_product_rate() {
            await this.$axios.post('suppliers/products/add_product_option_rates', {
                sup_prod_option_id: this.selectoption.id,
                name: this.r.name,
                start_date: this.r.start_date,
                end_date: this.r.end_date,
                drate: this.drates,
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('supplier/set_supplier_rates', data.data);
                    }
                });
            await this.$axios.get(`suppliers/products/get_product_option_rates/${this.selectoption.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.ratelist = data.data;
                        this.ratelist.forEach(rate => {
                            rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                        })
                    }
                });
            this.rates = false;
        },
        async update_supplier_product_option() {
            await this.$axios.patch(`suppliers/products/update_product_option/${this.edit.id}`, {
                name: this.f.name,
                supplier_prod_id: this.product.id,
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                });
            this.f.name = null;
            this.dialog = false;
        },
        async archiveOption(e) {
            await this.$axios.patch(`suppliers/products/delete_product_option/${e.id}`, {
                name: e.name,
                supplier_prod_id: e.supplier_prod_id,
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                });
            this.f.name = null;
        },
        async restoreOption(e) {
            await this.$axios.patch(`suppliers/products/restore_product_option/${e.id}`, {
                name: e.name,
                supplier_prod_id: e.supplier_prod_id,
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                });
            this.f.name = null;
        },
        async archive_rates(e) {
            await this.$axios.patch(`suppliers/products/delete_product_option_rates/${e.id}`, e)
                .then(({ data }) => {
                    if (data.response) {
                        this.ratelist = data.data;
                        this.ratelist.forEach(rate => {
                            rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                        })
                    }
                });
            this.f.name = null;
        },
        async duplicate_rates(e) {
            await this.$axios.patch(`suppliers/products/duplicate_product_option_rates/${e.id}`, e)
                .then(({ data }) => {
                    if (data.response) {
                        this.ratelist = data.data;
                        this.ratelist.forEach(rate => {
                            rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                        })
                    }
                });
        },
        insert_image(data) {
            this.$store.dispatch('supplier/set_supplier_product_options_edit', data)
            this.has_to_upload_image = true
        },
        remove_image(item) {
            item.image = null
            this.$store.dispatch('supplier/set_empty_supplier_product_options_edit', item)
            this.close_modal()
        },
        async view_rate_details(item) {
            await this.$store.dispatch('supplier/fetch_rates_view', item)
            this.view_rate_dialog = true
        },
        edit_rate(data, option) {
            this.selected_option = option
            console.log(option)
            this.$store.dispatch('supplier/set_edit_rates', data)
            this.edit_rate_dialog = true
        },
        async save_edited_data() {
            let tp = {
                id: this.get_edit_rates.id,
                net: this.edit_rate_model.net,
                gross: this.edit_rate_model.gross,
                commission: this.edit_rate_model.commission,
                markup: this.edit_rate_model.markup,
                updated_by: this.get_user.id
            }
            this.$store.dispatch('supplier/update_rates', tp)
            if (this.get_response.response) {
                this.close_edit_rate_dialog()
                this.getRates()
            }
        },
        async getRates() {
            let payload = {
                id: this.selected_option.sup_prod_option_id
            }
            console.log(payload)
            await this.$axios.get('suppliers/products/get_rates_for_view', payload)
                .then(({ data }) => {
                    this.ratelist = data.data
                    this.ratelist.forEach(rate => {
                        rate.supplierrates = rate.supplierrates.filter(x => x.itinerary_id == null)
                    })
                })
        },
        add_child(data) {
            this.$store.dispatch('supplier/set_add_option_children_parent_data', data)
            this.child_data_dialog = true
        },
        async add_child_option() {
            let tp = {
                name: this.child_data_model,
                spo_id: this.get_add_option_children_parent_data.id
            }
            this.$store.dispatch('supplier/post_product_option_children', tp)
            if (!this.get_response.response) {
                this.get_response.data.name.map(q => {
                    this.$toast(q)
                })
            } else {
                await this.$store.dispatch(
                    "supplier/fetch_supplier_product_option",
                    this.product.id
                );
                this.child_data_dialog = false
                this.child_data_model = null
            }
        },
        show_details_child(item) {
            this.$store.dispatch('supplier/set_child_data', item)
            this.show_child_data_dialog = true
        },
        show_dialog_rate_period_child(item) {
            this.rate_period_child = true
            this.$store.dispatch('supplier/set_rate_period_child', item)
        },
        close_child() {
            this.rate_period_child = false
        },
        async close_deep() {
            this.close_child()
            this.show_child_data_dialog = false
            await this.$store.dispatch(
                "supplier/fetch_supplier_product_option",
                this.product.id
            );
        }
    },
    watch: {
        async image_to_upload(newVal) {
            if (newVal !== null) {
                await this.$store.dispatch('supplier/update_image_product_options', newVal)
                this.$emit('getSupplier')
                this.close_modal()
            }
        }
    },
};
</script>
<style scoped>
.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: center;
}

.card .card-header-items .header-items {
    margin: 0px 0px;
}

.media-image:hover {
    cursor: pointer;
    box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}

.image-show-wrapper {
    width: 100%;
}

.image-show-wrapper .image-show {
    max-width: 50%;
    margin: 0 auto;
}

.image-show-wrapper .image-show-name {
    font-size: 10px;
    text-align: center;
}

.tags-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.tags-wrapper .tags {
    max-height: 50px;
    display: flex;
    background-color: #dcdee0;
}

.tags-wrapper .tags .tag-name {
    margin: auto 0;
    padding: 12px;
    color: #343642;
    font-weight: bold;
    font-size: 16px;
    background: rgb(237, 238, 239);
}

.tags-wrapper .tags .tag-action {
    margin: auto 0;
}

.media-button {
    text-align: center;
}

.media-button .input-media {
    /* color: rgb(82, 90, 104); */
    display: none;
}

.dialog-close {
    margin-left: -75px;
    position: absolute;
}

@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }

    .card .card-header-items .header-items {
        margin: 5px 5px 10px 5px;
    }
}
</style>
