<template>
    <v-dialog :value="custom_rate_dialog" persistent max-width="750">
        <v-card v-if="selected_rate">
            <v-card-title>
                Edit Rate for {{ selected_booking.option.product.supplier.supplier_name }}
                <v-spacer />
                <v-btn @click="close()" rounded icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title>
                <small>Currently using:
                    <label v-if="selected_rate.itinerary_id == null"> supplier rate</label>
                    <label v-else> custom rate</label>
                </small>
                <v-spacer />
                <v-btn v-if="selected_rate.itinerary_id != null" @click="deleteCustomRate()" small text
                    color="red">Revert to supplier rate</v-btn>
            </v-card-title>
            <v-card-text style="color:black;">
                <v-expansion-panels v-model="panel"
                    v-if="selected_rate.itinerary_id == null && selected_booking.option.product.supplier.service_type_id == 1 && selected_rate_period_days.length > 0"
                    class="mb-5">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Show per night rate breakdown
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            RATES FROM
                            <b>{{ $date(selected_rate_period.start_date).format('MMMM D, YYYY') }}</b> to
                            <b>{{ $date(selected_rate_period.end_date).format('MMMM D, YYYY') }}</b>
                            <br>
                            <v-data-table v-if="sorted_rate_period_days.length" :items="sorted_rate_period_days"
                                :headers="rate_headers" hide-default-footer disable-pagination class="rates-table mb-5">
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.day }}</td>
                                        <td>{{ item.net }}</td>
                                        <td>{{ item.gross }}</td>
                                        <td>{{ item.markup }}</td>
                                        <td>{{ item.commission }}</td>
                                        <td>
                                            <span v-if="bookingDaysMap[item.day]">
                                                {{ bookingDaysMap[item.day] }}
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                            <v-row v-if="!previous_rates" style="border: solid 1px lightgray;border-radius:4px;">
                                <v-col cols="12">
                                    <h3>Per Night Rates</h3>
                                </v-col>
                                <v-col cols="6">
                                    <b>
                                        Total Net For {{ selected_booking.count }}
                                        Count & {{ numberOfNights }} Nights:
                                    </b>
                                    {{ (totalNet * selected_booking.count).toFixed(2) }}
                                    <br>
                                    <b>Total Net Per Count:</b> {{ (totalNet).toFixed(2) }}
                                    <br>
                                    <br>
                                    <label style="font-size:16px;">
                                        <b>Net Per Count Per Night:</b>
                                        {{ (totalNet / numberOfNights).toFixed(2) }}
                                    </label>
                                </v-col>
                                <v-col cols="6">
                                    <b>
                                        Total Gross For {{ selected_booking.count }}
                                        Count & {{ numberOfNights }} Nights:
                                    </b>
                                    {{ (totalGross * selected_booking.count).toFixed(2) }}
                                    <br>
                                    <b>Total Gross Per Count:</b> {{ (totalGross).toFixed(2) }}
                                    <br>
                                    <br>
                                    <label style="font-size:16px;">
                                        <b>Gross Per Count Per Night:</b>
                                        {{ (totalGross / numberOfNights).toFixed(2) }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-row v-else style="border: solid 1px lightgray;border-radius:4px;">
                                <v-col cols="12">
                                    <h3>Initial Night Rates</h3>
                                </v-col>
                                <v-col cols="6">
                                    <b>
                                        Total Net For {{ selected_booking.count }}
                                        Count & {{ numberOfNights }} Nights:
                                    </b>
                                    {{ (sorted_rate_period_days[0].net * numberOfNights * selected_booking.count).toFixed(2) }}
                                    <br>
                                    <b>Total Net Per Count:</b> {{ (sorted_rate_period_days[0].net * numberOfNights).toFixed(2) }}
                                    <br>
                                    <br>
                                    <label style="font-size:16px;">
                                        <b>Net Per Count Per Night:</b>
                                        {{ (parseFloat(sorted_rate_period_days[0].net)).toFixed(2) }}
                                    </label>
                                </v-col>
                                <v-col cols="6">
                                    <b>
                                        Total Gross For {{ selected_booking.count }}
                                        Count & {{ numberOfNights }} Nights:
                                    </b>
                                    {{ (sorted_rate_period_days[0].gross * numberOfNights * selected_booking.count).toFixed(2) }}
                                    <br>
                                    <b>Total Gross Per Count:</b> {{ (sorted_rate_period_days[0].gross * numberOfNights).toFixed(2) }}
                                    <br>
                                    <br>
                                    <label style="font-size:16px;">
                                        <b>Gross Per Count Per Night:</b>
                                        {{ (parseFloat(sorted_rate_period_days[0].gross)).toFixed(2) }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-btn @click="previous_rates = !previous_rates" outlined color="primary" class="mt-5"
                                style="text-transform:initial;">
                                <b v-if="!previous_rates">View Initial Night Rates</b>
                                <b v-else>View Per Night Rates</b>
                            </v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="mt-10 mb-5">
                    Set a custom rate per person per
                    <label v-if="selected_booking.option.product.supplier.service_type_id == 1">night</label>
                    <label v-else>day</label>
                </div>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="updated_rate.net" @keyup="calculateGrossCommission()" label="Net"
                            outlined dense type="number" />
                        <v-text-field v-model="updated_rate.markup" @keyup="calculateGrossCommission()" label="Markup %"
                            outlined dense type="number" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_rate.gross" label="Gross" outlined dense type="number" />
                        <v-text-field v-model="updated_rate.commission" label="Commission" outlined dense disabled
                            type="number" />
                        <div class="text-right mb-3" style="margin-top:-20px;">
                            <v-btn v-if="selected_rate.itinerary_id == null" @click="saveCustomRate()" color="primary"
                                rounded text class="px-5">
                                Create Custom Rate
                            </v-btn>
                            <v-btn v-else @click="saveCustomRate()" color="primary" rounded class="px-5">
                                Update Custom Rate
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close()" color="primary" rounded class="px-5">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'custom_rate_dialog', 'selected_rate', 'selected_rate_period', 'selected_rate_period_days', 'selected_booking'
    ],
    data() {
        return {
            updated_rate: { ...this.selected_rate },
            panel: null,
            rate_headers: [
                { text: 'Day', value: 'day', sortable: false },
                { text: 'Net', value: 'net', sortable: false },
                { text: 'Gross', value: 'gross', sortable: false },
                { text: 'Markup', value: 'markup', sortable: false },
                { text: 'Commission', value: 'commission', sortable: false },
                { text: 'Booked Nights', sortable: false },
            ],
            previous_rates: false,
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        bookingDaysMap() {
            const map = {}
            const { check_in_date, check_out_date } = this.selected_booking

            if (!check_in_date || !check_out_date) {
                return map
            }

            const checkIn = this.$date(check_in_date)
            const checkOut = this.$date(check_out_date)
            let current = checkIn.clone()
            let dayNumber = 1

            while (current.isBefore(checkOut, 'day')) {
                const dayName = current.format('dddd') // e.g., 'Monday'

                // Assign "Day N" if not already assigned or handle multiple assignments
                if (!map[dayName]) {
                    map[dayName] = `Night ${dayNumber}`
                    dayNumber++
                } else {
                    // If the day repeats (e.g., multiple weeks), you can handle accordingly
                    // For simplicity, we'll append a comma-separated value
                    map[dayName] += `, Night ${dayNumber}`
                    dayNumber++
                }

                current = current.add(1, 'day')
            }

            return map
        },
        sorted_rate_period_days() {
            if (!this.selected_booking.check_in_date) {
                // If there's no check-in date, return the original array
                return this.selected_rate_period_days
            }

            // Get the day of the week index for check_in_date (0: Sunday, 1: Monday, ..., 6: Saturday)
            const checkInDayIndex = this.$date(this.selected_booking.check_in_date).day()

            // Define the order of days starting from check_in_day
            const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

            // Rearrange the weekDays array to start from checkInDayIndex
            const orderedWeekDays = [
                ...weekDays.slice(checkInDayIndex),
                ...weekDays.slice(0, checkInDayIndex),
            ]

            // Sort selected_rate_period_days based on the orderedWeekDays
            const sortedDays = orderedWeekDays.map(day => {
                return this.selected_rate_period_days.find(rateDay => rateDay.day === day)
            }).filter(Boolean) // Remove any undefined values if a day is not found

            return sortedDays
        },
        matchedRateDays() {
            return this.selected_rate_period_days.filter(day => this.bookingDaysMap[day.day])
        },
        totalNet() {
            return this.matchedRateDays.reduce((sum, day) => sum + parseFloat(day.net), 0)
        },
        totalGross() {
            return this.matchedRateDays.reduce((sum, day) => sum + parseFloat(day.gross), 0)
        },
        numberOfNights() {
            if (this.selected_booking == null)
                return 0
            const { check_in_date, check_out_date } = this.selected_booking

            if (!check_in_date || !check_out_date) {
                return 0
            }

            const checkIn = this.$date(check_in_date)
            const checkOut = this.$date(check_out_date)

            // Validate that check-in is before check-out
            if (!checkIn.isBefore(checkOut, 'day')) {
                return 0
            }

            const nights = checkOut.diff(checkIn, 'day', true)
            return Math.abs(Math.floor(nights))
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        saveCustomRate() {
            this.$emit('saveCustomRate', this.updated_rate)
        },
        deleteCustomRate() {
            this.$emit('deleteCustomRate', this.updated_rate)
        },
        calculateGrossCommission() {
            let gross = Number(this.updated_rate.net) + Number(this.updated_rate.net * (this.updated_rate.markup / 100))
            this.updated_rate.gross = Number(gross)
            this.updated_rate.commission = Number(this.updated_rate.markup / gross)
        },
    },
    watch: {
    }
}
</script>

<style scoped lang='scss'>
:deep(.rates-table td) {
    height: 20px !important;
}
</style>