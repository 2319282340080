<template>
    <v-dialog :value="view_documents_dialog" persistent max-width="900">
        <v-card v-if="itinerary">
            <v-card-title>
                <label>{{ documentTypeName }} documents</label>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-5">
                <v-data-table :items="itinerary.documents.filter(x => x.type == document_type)"
                    :headers="documents_headers" :sort-by.sync="sort_documents_by"
                    :sort-desc.sync="sort_documents_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ $date(item.created_at).format('MMM DD, YYYY h:mm A') }}</td>
                            <td>
                                <v-text-field v-model="item.name" @change="updateItineraryDocument(item)" filled dense class="mt-3" />
                            </td>
                            <td><a :href="item.url" target="_blank"
                                    style="padding:10px 20px;background-color:#1976D2;border-radius:24px;color:white;font-weight:700;text-decoration:initial;">View</a>
                            </td>
                            <td>{{ item.creator.name }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined class="pl-3 pr-3" rounded @click="close()">
                    <v-icon class="mr-2">mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'view_documents_dialog', 'itinerary', 'document_type'
    ],
    computed: {
        documentTypeName() {
            if(this.document_type == null) {
                return ''
            }
            return this.document_type.charAt(0).toUpperCase() + this.document_type.slice(1)
        },
    },
    data() {
        return {
            documents_headers: [
                { text: 'Date Created', value: 'created_at', sortable: true, width: '15%' },
                { text: 'Name', value: 'name', sortable: true, width: '55%' },
                { text: 'Link', value: 'url', sortable: true, width: '15%' },
                { text: 'Created By', value: 'created_by', sortable: true, width: '15%' },
            ],
            sort_documents_by: 'created_at',
            sort_documents_desc: true,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        async updateItineraryDocument(item) {
            await this.$axios.post(`v2/itineraries/update_itinerary_document/${item.id}`, item)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>