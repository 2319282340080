<template>
    <v-container fluid style="max-width:1200px;">
        <v-card elevation="0">
            <v-card-title>
                <v-btn @click="refreshBookings(true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <download-csv :data="paymentsCsv">
                    <v-btn rounded color="primary" class="ml-2">
                        <v-icon class="mr-2">mdi-download</v-icon>
                        CSV
                    </v-btn>
                </download-csv>
                <v-btn @click="openCreatePaymentDialog()" rounded color="primary" class="mx-2">
                    Create Payment
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :items="payments" :headers="payment_headers" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ $dayjs(item.created_at).format('MMM D, YYYY') }}
                                <br>
                                {{ $dayjs(item.created_at).format('h:mm A') }}
                            </td>
                            <td>
                                <v-tooltip v-if="item.paid_by_traveller" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            {{
                                                item.paid_by_traveller.first_name + ' '
                                                + item.paid_by_traveller.last_name
                                            }}
                                        </label>
                                    </template>
                                    <small>TID: {{ item.paid_by_traveller_id }}</small>
                                </v-tooltip>
                                <v-tooltip v-else-if="item.paid_by_user" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            Payment Taken By <br>
                                            {{ item.paid_by_user.name }}
                                        </label>
                                    </template>
                                    <small>UID: {{ item.paid_by_user_id }}</small>
                                </v-tooltip>
                            </td>
                            <td>
                                {{ item.type.name }}
                            </td>
                            <td>
                                {{ item.method }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + formatNumber(item.amount) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + formatNumber(item.surcharge) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + formatNumber(item.tip_amount) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + formatNumber(item.total_amount) }}
                            </td>
                            <td>
                                <div class="d-flex">
                                    <v-btn v-if="!item.paid_by_user" @click="openViewPaymentDialog(item)" icon>
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.paid_by_user" @click="openUpdatePaymentDialog(item)" icon>
                                        <v-icon>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.paid_by_user" @click="openDeletePaymentDialog(item)" icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-row style="font-size:16px;">
                    <v-col cols="6" class="text-right py-0">
                        <b>Itinerary Total:</b>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        {{ (itinerary.payment_currency ? itinerary.payment_currency.symbol : '') + formatNumber(calculatedGross) }}
                    </v-col>
                    <v-col cols="6" class="text-right py-0">
                        <b>Total Paid:</b>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        {{ (itinerary.payment_currency ? itinerary.payment_currency.symbol : '') + formatNumber(totalPaid) }}
                        ({{ (((totalPaid) / calculatedGross) * 100).toFixed(0) + '%' }})
                    </v-col>
                    <v-col cols="6" class="text-right py-0">
                        <b>Balance Remaining:</b>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        {{ (itinerary.payment_currency ? itinerary.payment_currency.symbol : '') + formatNumber(balanceRemaining) }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <ViewPaymentDialog v-if="selected_payment" :view_payment_dialog="view_payment_dialog" :itinerary="itinerary"
            :selected_payment="selected_payment" :payment_types="payment_types" @close="closeViewPaymentDialog"
            @updatePayment="updatePayment" />
        <CreatePaymentDialog :create_payment_dialog="create_payment_dialog" :itinerary="itinerary"
            :payment_types="payment_types" @close="closeCreatePaymentDialog" @createPayment="createPayment" />
        <UpdatePaymentDialog v-if="selected_payment" :update_payment_dialog="update_payment_dialog"
            :itinerary="itinerary" :selected_payment="selected_payment" :payment_types="payment_types"
            @close="closeUpdatePaymentDialog" @updatePayment="updatePayment" />
        <DeletePaymentDialog :delete_payment_dialog="delete_payment_dialog" @close="closeDeletePaymentDialog"
            @deletePayment="deletePayment" />
    </v-container>
</template>

<script>
import ViewPaymentDialog from './dialogs/ViewPaymentDialog.vue'
import CreatePaymentDialog from './dialogs/CreatePaymentDialog.vue'
import UpdatePaymentDialog from './dialogs/UpdatePaymentDialog.vue'
import DeletePaymentDialog from './dialogs/DeletePaymentDialog.vue'
export default {
    components: {
        ViewPaymentDialog, CreatePaymentDialog, UpdatePaymentDialog, DeletePaymentDialog,
    },
    props: [
        'itinerary', 'net', 'gross', 'exchange_rate',
    ],
    data() {
        return {
            payments: [],
            payment_types: [],
            payment_headers: [
                { text: 'Date', value: 'created_at' },
                { text: 'Paid By', value: 'paid_by_traveller_id' },
                { text: 'Type', value: 'payment_type_id' },
                { text: 'Payment Method', value: 'method' },
                { text: 'Itinerary Amount', value: 'amount', width: '100px' },
                { text: 'Surcharge', value: 'surcharge', width: '100px' },
                { text: 'Tip to Agent', value: 'tip_amount', width: '100px' },
                { text: 'Total Amount', value: 'total_amount', width: '100px' },
                { text: '' },
            ],
            sort_by: 'created_at',
            sort_desc: true,
            view_payment_dialog: false,
            create_payment_dialog: false,
            update_payment_dialog: false,
            delete_payment_dialog: false,
            selected_payment: null,
        }
    },
    async mounted() {
        this.getPayments()
        this.getPaymentTypes()
    },
    created() {
    },
    computed: {
        totalPaid() {
            return this.payments.reduce((sum, payment) => sum + payment.amount, 0)
        },
        exchangeRate() {
            return this.exchange_rate[this.itinerary.payment_currency.code]
        },
        calculatedGross() {
            if (this.itinerary.currency_id == this.itinerary.payment_currency_id) {
                return this.gross
            }
            else {
                return (this.gross * this.exchangeRate) / 100 * 103.5
            }
        },
        balanceRemaining() {
            return this.calculatedGross - this.totalPaid
        },
        paymentsCsv() {
            let csv_payments = []
            this.payments.forEach(payment => {
                let formatted_payment = {
                    PaymentID: payment.id,
                    PaymentDate: this.$date(payment.created_at).format('YYYY-MM-DD h:mm A'),
                    Company: this.itinerary.company.company_name,
                    Itinerary: this.itinerary.itinerary_name,
                    PaidTo: payment.paid_to_traveller ? payment.paid_to_traveller.first_name + ' ' + payment.paid_to_traveller.last_name : '',
                    PaidBy: payment.paid_by_traveller ? payment.paid_by_traveller.first_name + ' ' + payment.paid_by_traveller.last_name : payment.paid_by_user ? 'Payment Taken By ' + payment.paid_by_user.name : '-',
                    Type: payment.type.name,
                    Method: payment.method,
                    Currency: payment.currency.code,
                    ItineraryAmount: payment.amount.toFixed(2),
                    Surcharge: payment.surcharge.toFixed(2),
                    TipToAgent: payment.tip_amount.toFixed(2),
                    TotalAmount: payment.total_amount.toFixed(2),
                    TransactionRef: payment.vendor_tx_code,
                }
                csv_payments.push(formatted_payment)
            })
            return csv_payments
        },
    },
    methods: {
        async refreshBookings(message = false) {
            this.$emit('refreshBookings')
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getPayments() {
            let payload = {
                itinerary_id: this.itinerary.id,
            }
            await this.$axios.get('v2/payments/get_payments', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payments = data.data
                    }
                })
        },
        async getPaymentTypes() {
            let payload = {
                is_public: 1,
            }
            await this.$axios.get('v2/payments/get_payment_types', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payment_types = data.data
                    }
                })
        },
        openViewPaymentDialog(item) {
            this.view_payment_dialog = true
            this.selected_payment = item
        },
        closeViewPaymentDialog() {
            this.view_payment_dialog = false
            this.selected_payment = null
        },
        openCreatePaymentDialog() {
            this.create_payment_dialog = true
        },
        closeCreatePaymentDialog() {
            this.create_payment_dialog = false
        },
        async createPayment(new_payment) {
            await this.$axios.post('v2/payments/create_payment', new_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeCreatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdatePaymentDialog(item) {
            this.update_payment_dialog = true
            this.selected_payment = item
        },
        closeUpdatePaymentDialog() {
            this.update_payment_dialog = false
            this.selected_payment = null
        },
        async updatePayment(updated_payment) {
            await this.$axios.post(`v2/payments/update_payment/${updated_payment.id}`, updated_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeUpdatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeletePaymentDialog(item) {
            this.delete_payment_dialog = true
            this.selected_payment = item
        },
        closeDeletePaymentDialog() {
            this.delete_payment_dialog = false
            this.selected_payment = null
        },
        async deletePayment() {
            await this.$axios.delete(`v2/payments/delete_payment/${this.selected_payment.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeDeletePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }).format(number)
        },
    },
}
</script>

<style scoped></style>