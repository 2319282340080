<template>
    <v-dialog :value="view_payment_dialog" persistent max-width="500">
        <v-card v-if="selected_payment">
            <v-card-title class="d-flex">
                View Payment
                <v-spacer />
                <v-btn @click="close()" variant="text" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="justify-center">
                    <v-col cols="12">
                        Created at: {{ $date(selected_payment.created_at).format('MMM D, YYYY h:mm A') }}
                    </v-col>
                    <v-col cols="6">
                        Payment Type
                        <div style="font-size:16px;color:black;">
                            {{ selected_payment.type.name }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Currency
                        <div style="font-size:16px;color:black;">
                            {{ selected_payment.currency.code }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Itinerary Amount
                        <div style="font-size:16px;color:black;">
                            {{ (selected_payment.currency ? selected_payment.currency.symbol : '')
                                + formatNumber(selected_payment.amount) }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Surcharge
                        <div style="font-size:16px;color:black;">
                            {{ (selected_payment.currency ? selected_payment.currency.symbol : '')
                                + formatNumber(selected_payment.surcharge) }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Tip
                        <div style="font-size:16px;color:black;">
                            {{ (selected_payment.currency ? selected_payment.currency.symbol : '')
                                + formatNumber(selected_payment.tip_amount) }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Total Amount
                        <div style="font-size:16px;color:black;">
                            {{ (selected_payment.currency ? selected_payment.currency.symbol : '')
                                + formatNumber(selected_payment.total_amount) }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Transaction Ref
                        <div style="font-size:16px;color:black;">
                            {{ selected_payment.vendor_tx_code }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        Payment Method
                        <div style="font-size:16px;color:black;">
                            {{ selected_payment.method }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="updated_payment.notes" label="Notes" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Close
                </v-btn>
                <v-btn @click="updatePayment()" rounded color="primary" class="px-5">
                    Save Notes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: [
        'view_payment_dialog', 'selected_payment', 'itinerary', 'payment_types',
    ],
    data() {
        return {
            updated_payment: { ...this.selected_payment },
        }
    },
    computed: {
        ...mapGetters({
            get_currencies: 'itineraryV2/get_currencies',
        }),
    },
    methods: {
        close() {
            this.$emit('close')
        },
        updatePayment() {
            this.$emit('updatePayment', this.updated_payment)
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }).format(number)
        },
    },
}
</script>