<template>
    <div>
        <div class="header">
            <h3>{{ get_selected_company.company_name }}</h3>
        </div>
        <div class="card">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="card-header">Itineraries</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="card-header-items">
                        <v-btn @click="openCreateItineraryDialog()" rounded color="primary" dark class="px-5">
                            Create itinerary
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="px-3">
                    <v-text-field label="Itinerary Name" prepend-icon="mdi-magnify" v-model="itinerary_name"
                        @keyup.enter="searchItineraries()"></v-text-field>
                </v-col>
                <v-col class="px-3">
                    <v-text-field label="Itinerary Number" prepend-icon="mdi-magnify" v-model="itinerary_number"
                        @keyup.enter="searchItineraries()"></v-text-field>
                </v-col>
                <v-col class="px-3">
                    <v-autocomplete v-model="selected_assignee" label="Filter by assignee"
                        :items="[{ name: 'None', value: null }, ...users]" item-text="name"
                        item-value="id"></v-autocomplete>
                </v-col>
                <v-col class="px-3">
                    <v-select multiple v-model="selected_status" label="Filter by status"
                        :items="[{ name: 'None', value: null }, ...get_statuses]" item-text="name"
                        item-value="id"></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    Start Date
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="start_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_from_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="start_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_to_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    End Date
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="end_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_from_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="end_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_to_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <div style="display:flex;align-items:center;justify-content:center;">
                        <v-btn @click="searchItineraries()" rounded color="primary">Search</v-btn>
                        <v-btn @click="resetSearch()" rounded text class="ml-2">Reset</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="pt-5">
                    <v-divider></v-divider>
                    <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
                    <v-data-table v-if="loaded" :headers="headers" :items="itineraries" item-key="id"
                        :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>
                                    <v-tooltip bottom v-if="item.creator">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip class="data-chip" v-bind="attrs" v-on="on">
                                                {{ item.creator.name.charAt(0).toUpperCase() }}
                                            </v-chip>
                                        </template>
                                        <span>Created by:<br>{{ item.creator.name }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <div style="display:flex;align-items:center;">
                                        <v-btn @click="openItinerary(item)" text
                                            style="font-weight:700;font-size:16px;color:#389A74;text-transform:initial;padding:0px;letter-spacing:initial;max-width:180px;height:auto;text-align:left;">
                                            {{ item.itinerary_name }}
                                        </v-btn>
                                        <v-btn icon small class="ml-2">
                                            <a :href="app_url + 'itinerary/update/' + item.id" target="_blank">
                                                <v-icon style="color:#389A74;" size="20">mdi-open-in-new</v-icon>
                                            </a>
                                        </v-btn>
                                    </div>
                                </td>
                                <td>{{ item.itinerary_number }}</td>
                                <td>{{ $date(item.start_date).format('MMM DD, YYYY') }}</td>
                                <td>{{ $date(item.end_date).format('MMM DD YYYY') }}</td>
                                <td>
                                    <div v-for="(contributor, index) in item.contributors.filter(x => x.user.user_role_id == 2)"
                                        :key="index">
                                        {{ contributor.user.name }}
                                    </div>
                                </td>
                                <td>{{ item.tour_type ? item.tour_type.name : '' }}</td>
                                <td>{{ item.currency ? item.currency.code : '' }}</td>
                                <td>
                                    <div class="d-flex align-center">
                                        {{ formatNumber(item.gross) }}
                                        <v-icon v-if="item && item.gross_locked" color="darkgrey" class="ml-2">
                                            mdi-lock-check
                                        </v-icon>
                                    </div>
                                </td>
                                <td>{{ $date(item.created_at).format('MMM DD, YYYY') }}</td>
                                <td>
                                    <v-checkbox v-model="item.is_confirm" @click.stop="updateItinerary(item)">
                                    </v-checkbox>
                                </td>
                                <td>
                                    <div>
                                        <v-select v-model="item.itinerary_status_id" :items="get_statuses"
                                            item-value="id" item-text="name" chips
                                            @change="updateItinerary(item)"></v-select>
                                    </div>
                                </td>
                                <td>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="duplicateItinerary(item)">
                                                <v-list-item-title>Duplicate</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openDeleteItineraryDialog(item)">
                                                <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <CreateItineraryDialog :create_itinerary_dialog="create_itinerary_dialog"
            @closeCreateItineraryDialog="closeCreateItineraryDialog" />

        <v-dialog v-model="delete_itinerary_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to delete this itinerary?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteItineraryDialog()" text>
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItinerary()" text color="red">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateItineraryDialog from './CreateItineraryDialog.vue'
export default {
    name: 'Itineraries',
    components: {
        CreateItineraryDialog,
    },
    props: [],
    data: () => ({
        loaded: false,
        create_itinerary_dialog: false,
        delete_itinerary_dialog: false,
        selected_itinerary: null,
        itineraries: [],
        users: [],
        headers: [
            { text: '', width: '60px', sortable: false },
            { text: 'Itinerary Name', value: 'itinerary_name', width: '250px' },
            { text: 'Itinerary Number', value: 'itinerary_number', width: '150px' },
            { text: 'Start Date', value: 'start_date', width: '120px' },
            { text: 'End Date', value: 'end_date', width: '120px' },
            { text: 'Assignees', width: '160px', sortable: false },
            { text: 'Tour Type', width: '60px', value: 'tour_type_id' },
            { text: 'Currency', value: 'currency_id', width: '20px' },
            { text: 'Total Gross', value: 'gross', width: '80px' },
            { text: 'Created', value: 'created_at', width: '150px' },
            { text: 'Confirmed', value: 'is_confirm', width: '20px' },
            { text: 'Status', sortable: false, width: '250px' },
            { text: '', value: 'action', sortable: false },
        ],
        sort_by: 'id',
        sort_desc: true,
        itinerary_name: null,
        itinerary_number: null,
        selected_assignee: null,
        selected_status: null,
        start_from_menu: false,
        start_to_menu: false,
        start_from_date: null,
        start_to_date: null,
        end_from_menu: false,
        end_to_menu: false,
        end_from_date: null,
        end_to_date: null,
        app_url: null,
    }),
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.getItineraries()
        this.getUsers()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
            get_statuses: 'itineraryV2/get_statuses',
        }),
    },
    methods: {
        async getItineraries() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('v2/itineraries/get_latest_itineraries', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.itineraries = data.data
                        this.loaded = true
                    }
                })
        },
        async getUsers() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('v2/users/get_users', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        async searchItineraries() {
            this.loaded = false
            this.start_from_menu = false
            this.start_to_menu = false
            this.end_from_menu = false
            this.end_to_menu = false
            let payload = {
                itinerary_name: (this.itinerary_name != null && this.itinerary_name != '') ? this.itinerary_name : null,
                itinerary_number: (this.itinerary_number != null && this.itinerary_number != '') ? this.itinerary_number : null,
                assignee: (this.selected_assignee != null && this.selected_assignee != 'None') ? this.selected_assignee : null,
                itinerary_status_id: (this.selected_status != null && this.selected_status != 'None') ? this.selected_status : null,
                start_from_date: (this.start_from_date != null && this.start_from_date != 'None') ? this.start_from_date : null,
                start_to_date: (this.start_to_date != null && this.start_to_date != 'None') ? this.start_to_date : null,
                end_from_date: (this.end_from_date != null && this.end_from_date != 'None') ? this.end_from_date : null,
                end_to_date: (this.end_to_date != null && this.end_to_date != 'None') ? this.end_to_date : null,
            }
            if (payload.itinerary_name == null && payload.itinerary_number == null && payload.assignee == null && payload.itinerary_status_id == null && payload.start_from_date == null && payload.start_to_date == null && payload.end_from_date == null && payload.end_to_date == null) {
                this.getItineraries()
            } else {
                this.$axios.get(`v2/itineraries/get_itineraries_by_filter/${this.get_selected_company.id}`, payload)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.itineraries = data.data
                            this.loaded = true
                        }
                    })
            }
        },
        resetSearch() {
            this.loaded = false
            this.itinerary_name = null
            this.itinerary_number = null
            this.selected_assignee = null
            this.selected_status = null
            this.start_from_date = null
            this.start_to_date = null
            this.end_from_date = null
            this.end_to_date = null
            this.getItineraries()
        },
        openItinerary(item) {
            this.$router.push(`/itinerary/update/${item.id}`)
        },
        openCreateItineraryDialog() {
            this.create_itinerary_dialog = true
        },
        closeCreateItineraryDialog() {
            this.create_itinerary_dialog = false
        },
        async updateItinerary(item) {
            let payload = {
                ...item
            }
            await this.$axios.post(`v2/itineraries/update_itinerary/${item.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async duplicateItinerary(item) {
            this.$toast('Please wait...')
            await this.$axios.post(`v2/itineraries/duplicate_itinerary/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getItineraries()
                    }
                })
        },
        openDeleteItineraryDialog(item) {
            this.delete_itinerary_dialog = true
            this.selected_itinerary = item
        },
        closeDeleteItineraryDialog() {
            this.delete_itinerary_dialog = false
            this.selected_itinerary = null
        },
        deleteItinerary() {
            this.$axios.delete(`v2/itineraries/delete_itinerary/${this.selected_itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteItineraryDialog()
                        this.getItineraries()
                    }
                })
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }).format(number)
        },
    },
};
</script>

<style scoped>
.header {
    max-width: 100%;
    background-color: #79a2bd;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 50px;
}

.header h3 {
    color: white;
}

.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: right;
}

.card .card-header-items .header-items {
    margin: 0px 10px;
}

.filtertabs-wrapper {
    max-width: 100%;
    margin-top: 18px;
}

.data-chip {
    background-color: rgb(56, 154, 116) !important;
    font-weight: bolder;
    font-size: 12px;
    color: white;
}

.text-center {
    text-align: center;
}

@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }

    .card .card-header-items .header-items {
        margin: 5px 5px 10px 5px;
    }
}

:deep(.v-btn span) {
    display: contents !important;
    white-space: initial;
}
</style>
