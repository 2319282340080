<template>
    <v-container fluid>
        <v-row no-gutters v-if="show_occupancy" class="mt-5" style="margin-bottom:200px;">
            <v-col cols="12" style="border: solid 1px grey;border-radius:4px;padding:10px;margin:10px;">
                <h2>Shared Costs</h2>
                <v-row v-for="(booking, index) in bookings_shared" :key="'shared' + index" no-gutters
                    :set="days = (itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                        ? $date(booking.check_out_date).diff($date(booking.check_in_date), 'd') + 1 : $date(booking.check_out_date).diff($date(booking.check_in_date), 'd')">
                    <v-col cols="9">
                        <b>{{ booking.option.product.supplier.supplier_name }}</b> - {{
                            booking.option.product.name }} - <b>x{{ booking.count }}</b>
                        {{ booking.option.name }}
                        <b v-if="booking.option.product.supplier.service_type_id == 1">
                            x{{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                        </b>
                        <b v-else>
                            x{{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                        </b>
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }}
                            <label v-if="booking.option.product.supplier.currency_id == itinerary.currency_id">
                                {{ (booking.rate_day.gross * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                            <label v-else>
                                {{ (booking.rate_day.gross * conversion * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                        </b>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="9">
                        Service Charge:
                    </v-col>
                    <v-col cols="3">
                        <b>{{ itinerary.currency.code }} {{ itinerary.service_charge }}</b>
                    </v-col>
                    <v-col cols="9">
                        ORR:
                    </v-col>
                    <v-col cols="3">
                        <b>{{ itinerary.currency.code }} {{ itinerary.orr }}</b>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2" style="font-size:16px;">
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total Shared Costs:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ sharedCosts.toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total Shared Costs Per Person ({{ itinerary.travellers_number }} pax):
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (sharedCosts /
                                itinerary.travellers_number).toFixed(2) }}
                        </b>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" style="border: solid 1px grey;border-radius:4px;padding:10px;margin:10px;">
                <h2>Single Occupancy</h2>
                <v-row v-for="(booking, index) in bookings_single" :key="'single' + index" no-gutters
                    :set="days = (itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                        ? $date(booking.check_out_date).diff($date(booking.check_in_date), 'd') + 1 : $date(booking.check_out_date).diff($date(booking.check_in_date), 'd')">
                    <v-col cols="9">
                        <b>{{ booking.option.product.supplier.supplier_name }}</b> - {{
                            booking.option.product.name }} - <b>x{{ booking.count }}</b>
                        {{ booking.option.name }}
                        <b v-if="booking.option.product.supplier.service_type_id == 1">
                            x{{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                        </b>
                        <b v-else>
                            x{{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                        </b>
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }}
                            <label v-if="booking.option.product.supplier.currency_id == itinerary.currency_id">
                                {{ (booking.rate_day.gross * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                            <label v-else>
                                {{ (booking.rate_day.gross * conversion * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                        </b>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (singleRoomCosts).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;font-size:16px;" class="pr-2 mt-2">
                        + Total Shared Costs Per Person:
                    </v-col>
                    <v-col cols="3" class="mt-2" style="font-size:16px;">
                        <b>
                            {{ itinerary.currency.code }} {{ ((singleRoomCosts) +
                                (sharedCosts /
                                    itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" style="border: solid 1px grey;border-radius:4px;padding:10px;margin:10px;">
                <h2>Double Occupancy</h2>
                <v-row v-for="(booking, index) in bookings_double" :key="'double' + index" no-gutters
                    :set="days = (itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                        ? $date(booking.check_out_date).diff($date(booking.check_in_date), 'd') + 1 : $date(booking.check_out_date).diff($date(booking.check_in_date), 'd')">
                    <v-col cols="9">
                        <b>{{ booking.option.product.supplier.supplier_name }}</b> - {{
                            booking.option.product.name }} - <b>x{{ booking.count }}</b>
                        {{ booking.option.name }}
                        <b v-if="booking.option.product.supplier.service_type_id == 1">
                            x{{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                        </b>
                        <b v-else>
                            x{{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                        </b>
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }}
                            <label v-if="booking.option.product.supplier.currency_id == itinerary.currency_id">
                                {{ (booking.rate_day.gross * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                            <label v-else>
                                {{ (booking.rate_day.gross * conversion * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                        </b>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ doubleRoomCosts.toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;font-size:16px;" class="pr-2 mt-2">
                        Total Double Occupancy Costs / 2 / number of double bookings:
                    </v-col>
                    <v-col cols="3" class="mt-2" style="font-size:16px;">
                        <b>
                            {{ itinerary.currency.code }} {{ ((doubleRoomCosts / 2) /
                                bookings_double.length).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;font-size:16px;" class="pr-2 mt-2">
                        + Total Shared Costs Per Person:
                    </v-col>
                    <v-col cols="3" class="mt-2" style="font-size:16px;">
                        <b>
                            {{ itinerary.currency.code }} {{ (((doubleRoomCosts / 2) /
                                bookings_double.length) + (sharedCosts /
                                    itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" style="border: solid 1px grey;border-radius:4px;padding:10px;margin:10px;">
                <h2>Triple Occupancy</h2>
                <v-row v-for="(booking, index) in bookings_triple" :key="'triple' + index" no-gutters
                    :set="days = (itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                        ? $date(booking.check_out_date).diff($date(booking.check_in_date), 'd') + 1 : $date(booking.check_out_date).diff($date(booking.check_in_date), 'd')">
                    <v-col cols="9">
                        <b>{{ booking.option.product.supplier.supplier_name }}</b> - {{
                            booking.option.product.name }} - <b>x{{ booking.count }}</b>
                        {{ booking.option.name }}
                        <b v-if="booking.option.product.supplier.service_type_id == 1">
                            x{{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                        </b>
                        <b v-else>
                            x{{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                        </b>
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }}
                            <label v-if="booking.option.product.supplier.currency_id == itinerary.currency_id">
                                {{ (booking.rate_day.gross * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                            <label v-else>
                                {{ (booking.rate_day.gross * conversion * (days > 0 ? days : 1) *
                                    booking.count).toFixed(2) }}
                            </label>
                        </b>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ tripleRoomCosts.toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;font-size:16px;" class="pr-2 mt-2">
                        Total Triple Occupancy Costs / 3 / number of triple bookings:
                    </v-col>
                    <v-col cols="3" class="mt-2" style="font-size:16px;">
                        <b>
                            {{ itinerary.currency.code }} {{ ((tripleRoomCosts / 3) /
                                bookings_triple.length).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;font-size:16px;" class="pr-2 mt-2">
                        + Total Shared Costs Per Person:
                    </v-col>
                    <v-col cols="3" class="mt-2" style="font-size:16px;">
                        <b>
                            {{ itinerary.currency.code }} {{ (((tripleRoomCosts / 3) /
                                bookings_triple.length) + (sharedCosts /
                                    itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" style="border: solid 1px grey;border-radius:4px;padding:10px;margin:10px;">
                <h2>Summary</h2>
                <v-row no-gutters style="font-size:16px;">
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Total Shared Costs Per Person ({{ itinerary.travellers_number }} pax):
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (sharedCosts /
                                itinerary.travellers_number).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Single:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ ((singleRoomCosts) +
                                (sharedCosts /
                                    itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Double:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (((doubleRoomCosts / 2) /
                                bookings_double.length) + (sharedCosts /
                                    itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Triple:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (((tripleRoomCosts / 3) /
                                bookings_triple.length) + (sharedCosts / itinerary.travellers_number)).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Difference between single and double:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (((singleRoomCosts) +
                                (sharedCosts / itinerary.travellers_number)) - (((doubleRoomCosts / 2) /
                                    bookings_double.length) + (sharedCosts / itinerary.travellers_number))).toFixed(2) }}
                        </b>
                    </v-col>
                    <v-col cols="9" style="text-align:right;" class="pr-2">
                        Difference between double and single:
                    </v-col>
                    <v-col cols="3">
                        <b>
                            {{ itinerary.currency.code }} {{ (((doubleRoomCosts /
                                bookings_single.length) +
                                (sharedCosts / itinerary.travellers_number)) - (((tripleRoomCosts / 2) /
                                    bookings_triple.length) + (sharedCosts / itinerary.travellers_number))).toFixed(2) }}
                        </b>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
        'show_occupancy', 'bookings', 'itinerary',
    ],
    data() {
        return {
        }
    },
    async mounted() {
    },
    created() {
    },
    computed: {
        bookings_shared() {
            return this.bookings.filter(booking => booking.is_shared == 1)
        },
        bookings_single() {
            return this.bookings.filter(booking => booking.is_shared == 0 && booking.occupancy == 1)
        },
        bookings_double() {
            return this.bookings.filter(booking => booking.is_shared == 0 && booking.occupancy == 2)
        },
        bookings_triple() {
            return this.bookings.filter(booking => booking.is_shared == 0 && booking.occupancy == 3)
        },
        sharedCosts() {
            let total = 0
            this.bookings.forEach(booking => {
                if (booking.is_shared == 1) {
                    let days = (this.itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    if (this.itinerary.currency_id != booking.option.product.supplier.currency_id) {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count * this.conversion
                    }
                    else {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count
                    }
                }
            })
            return Math.abs(total) + parseFloat(this.itinerary.service_charge) + parseFloat(this.itinerary.orr)
        },
        singleRoomCosts() {
            let total = 0
            this.bookings.forEach(booking => {
                if (booking.is_shared == 0 && booking.occupancy == 1) {
                    let days = (this.itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    if (this.itinerary.currency_id != booking.option.product.supplier.currency_id) {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count * this.conversion
                    }
                    else {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count
                    }
                }
            })
            return Math.abs(total)
        },
        doubleRoomCosts() {
            let total = 0
            this.bookings.forEach(booking => {
                if (booking.is_shared == 0 && booking.occupancy == 2) {
                    let days = (this.itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    if (this.itinerary.currency_id != booking.option.product.supplier.currency_id) {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count * this.conversion
                    }
                    else {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count
                    }
                }
            })
            return Math.abs(total)
        },
        tripleRoomCosts() {
            let total = 0
            this.bookings.forEach(booking => {
                if (booking.is_shared == 0 && booking.occupancy == 3) {
                    let days = (this.itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    if (this.itinerary.currency_id != booking.option.product.supplier.currency_id) {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count * this.conversion
                    }
                    else {
                        total += (booking.rate_day ? booking.rate_day.gross : 0) * (days > 0 ? days : 1) * booking.count
                    }
                }
            })
            return Math.abs(total)
        },
    },
    methods: {
    },
    watch: {
    }
}
</script>