<template>
    <v-container fluid style="max-width:1500px;">
        <v-card elevation="0">
            <v-card-title>
                <v-btn @click="refreshItinerary(true, true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
            </v-card-title>
            <v-card-text class="mt-5">
                <div>
                    <v-btn @click="document_type = 'admin'" rounded color="primary" class="mr-3"
                        :disabled="document_type == 'admin'">Admin Documents</v-btn>
                    <v-btn @click="document_type = 'client'" rounded color="primary" class="mr-3"
                        :disabled="document_type == 'client'">Client Documents</v-btn>
                    <v-btn @click="document_type = 'client itinerary'" rounded color="primary"
                        :disabled="document_type == 'client itinerary'">Published Itineraries</v-btn>
                </div>
                <v-file-input v-model="document_file" v-if="document_type != 'itinerary'" @change="uploadDocument()"
                    :label="'Upload ' + document_type + ' file'" style="width:25%;" />
                <div v-else class="pa-6">
                    Go to Preview tab to publish itinerary. Most recently published one will be shown on client portal.
                </div>
                <v-data-table
                    :items="document_type == 'client itinerary' ? itinerary.documents.filter(x => x.type.includes('client itinerary')) : itinerary.documents.filter(x => x.type.includes(document_type) && !x.type.includes('client itinerary'))"
                    :headers="documents_headers" :sort-by.sync="sort_documents_by"
                    :sort-desc.sync="sort_documents_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ $date(item.created_at).format('MMM DD, YYYY h:mm A') }}</td>
                            <td>
                                <v-text-field v-model="item.name" @change="updateItineraryDocument(item)" filled dense
                                    class="mt-3" />
                            </td>
                            <td>
                                {{ item.type.split(' ')[1] }}
                            </td>
                            <td class="text-center">
                                <a :href="item.url" target="_blank">
                                    <v-img v-if="['jpg', 'png', 'jpeg', 'gif'].includes(item.extension)" :src="item.url"
                                        height="75" width="75" contain class="mx-auto" />
                                    <v-icon v-if="['pdf'].includes(item.extension)" size="75" color="primary">
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-if="['doc', 'docx'].includes(item.extension)" size="75">
                                        mdi-file-doc-box
                                    </v-icon>
                                </a>
                            </td>
                            <td>
                                <a :href="item.url" target="_blank"
                                    style="padding:10px 20px;background-color:#1976D2;border-radius:24px;color:white;font-weight:700;text-decoration:initial;">View</a>
                            </td>
                            <td>{{ item.creator.name }}</td>
                            <td>
                                <v-btn @click="openDeleteDocumentDialog(item)" color="red" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="delete_document_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to delete this document?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteDocumentDialog()" text>
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteDocument()" text color="red">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    props: [
        'itinerary',
    ],
    computed: {
    },
    data() {
        return {
            document_type: 'admin',
            documents_headers: [
                { text: 'Date Created', value: 'created_at', sortable: true, width: '15%' },
                { text: 'Name', value: 'name', sortable: true, width: '35%' },
                { text: 'Type', value: 'type', sortable: true, width: '10%' },
                { text: 'Preview', value: 'url', sortable: true, width: '10%' },
                { text: 'Link', value: 'url', sortable: true, width: '10%' },
                { text: 'Created By', value: 'created_by', sortable: true, width: '10%' },
                { text: '', sortable: false, width: '10%' },
            ],
            sort_documents_by: 'created_at',
            sort_documents_desc: true,
            document_file: null,
            delete_document_dialog: false,
            selected_document: null,
        }
    },
    methods: {
        async refreshItinerary(message = false, success = false) {
            this.$emit('refreshItinerary', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async uploadDocument() {
            if (this.document_file != null) {
                const formData = new FormData()
                formData.append('itinerary_id', this.itinerary.id)
                formData.append('type', this.document_type + ' file')
                formData.append('file', this.document_file)

                await this.$axios.post('v2/itineraries/create_itinerary_document', formData)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.$toast.success(data.message)
                            this.document_file = null
                            this.$emit('refreshItinerary')
                        }
                        else {
                            this.$toast.error(data.message)
                        }
                    })
            }
        },
        async updateItineraryDocument(item) {
            await this.$axios.post(`v2/itineraries/update_itinerary_document/${item.id}`, item)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteDocumentDialog(item) {
            this.selected_document = item
            this.delete_document_dialog = true
        },
        closeDeleteDocumentDialog() {
            this.selected_document = null
            this.delete_document_dialog = false
        },
        async deleteDocument() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_document/${this.selected_document.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteDocumentDialog()
                        this.$emit('refreshItinerary')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>